import { create } from "zustand"
import { devtools, persist } from "zustand/middleware";

import { 
  Edge, 
  EdgeDeployStatus, 
  EdgeGlobalStore, 
  EdgeInfraType, 
  EdgeStatus, 
  EdgeStore, 
  PageInfo 
} from "types";

import {
  getEdgesApi,
  getEdgeApi,
  getEdgeEndpointApi,
  createEdgeApi,
  updateEdgeApi,
  deleteEdgeApi,
  disableEdgeSubscriptionApi,
  enableEdgeSubscriptionApi,
} from "api";
import { FilterValue, SorterResult } from "antd/es/table/interface";
import { DataType } from "@textea/json-viewer";
import { convertToApiFilters, convertToApiSorters } from "./utils";
import { useAppConfigStore } from "./appconfig";

export const useEdgeStore = create<EdgeStore>()(
  devtools((set, get) => ({
      totalEdgesCount: 0,
      edges: [],
      selectedEdge: {status: EdgeStatus.Offline, deployStatus: EdgeDeployStatus.Initiated} as Edge,
      edgePage: {number:1, size: 12, total: 0} as PageInfo,
      edgeFilter: {} as Record<string, FilterValue | null>,
      edgeSorter: {} as SorterResult<DataType>,
      edgeSearchTextMap: new Map<string, string>(),
      preferenceColumns: ["displayName", "description", "tags", "manageType", "status", "tenantTags", "actions"] as string[],
      defaultPreferenceColumns: ["displayName", "description" ] as string[],
      
      getSelectedEdge: async (edgeId) => {
        try {
          const edge = await getEdgeApi(edgeId);

          set((state) => ({
            ...state,
            selectedEdge: edge,
          }));
          return edge;
        } catch (error: any) {
          console.error(error);
          throw new Error(error);
        }
      },

      clearSelectedEdge: () => {
        set((state) => ({
          ...state,
          selectedEdge: {status: EdgeStatus.Offline, deployStatus: EdgeDeployStatus.Initiated} as Edge,
        }));
      },

      createEdge: async (edgeIn: Edge, deployNow?: boolean) => {
        try {
          const edgeOut = await createEdgeApi(edgeIn, deployNow);
          set((state) => ({
            ...state,
            selectedEdge: edgeOut,
          }));
          return edgeOut
  
        } catch (error: any) {
          console.error(error);
          throw new Error(error);
        }
      },

      updateEdge: async (edgeIn: Edge, deployNow?: boolean) => {
        try {
          await updateEdgeApi(edgeIn, deployNow);
          const edgeOut = await getEdgeApi(edgeIn.id)
          set((state) => ({
            ...state,
            selectedEdge: edgeOut,
          }));
        } catch (error: any) {
          console.error(error);
          throw new Error(error);
        }
      },

      deleteEdge: async (edgeId: string) => {
        try {
          await deleteEdgeApi(edgeId);
          set((state) => ({
            ...state,
          }));
        } catch (error: any) {
          console.error(error);
          throw new Error(error);
        }
      },
  
      getEdges: async (paginate, infraType, manageType) => {
        try {
          const pageInfo = get().edgePage;
          const filters = get().edgeFilter;
          const searches = get().edgeSearchTextMap;
          const sorters = get().edgeSorter;

          const edgeFilters = convertToApiFilters(filters, searches);
          const appSorters = convertToApiSorters(sorters);
          //defualt sorting field
          if (appSorters?.length == 0) {
            appSorters.push("-status");
          }

          let edges = [] as Edge[];
          let totalCount = 0;
          if (paginate) {
            [edges, totalCount] = await getEdgesApi(true, pageInfo.number, pageInfo.size, infraType, manageType, edgeFilters, appSorters);
          } else {
            [edges, totalCount] = await getEdgesApi(true, undefined, undefined, infraType, manageType, edgeFilters, appSorters);
          }

          set((state) => ({
            ...state,
            edges: [...edges],
            totalEdgesCount: totalCount,
          }));
          return edges;
        } catch (error: any) {
          console.error(error);
          throw new Error(error);
        }
      },

      clearEdges: () => {
        set(() => ({
          edges: [],
          totalEdgesCount: 0,
          selectedEdge: {status: EdgeStatus.Offline, deployStatus: EdgeDeployStatus.Initiated} as Edge,
        }));
      },

      resetEdgeStore: () => {
        set((state) => ({
          ...state,
          edges: [],
          totalEdgesCount: 0,
          selectedEdge: {} as Edge,
          edgeFilter: {} as Record<string, FilterValue | null>,
          edgeSorter: {} as SorterResult<DataType>,
          edgeSearchTextMap: new Map<string, string>(),
          appsPage: { number: 1, size: 12, total: 0 } as PageInfo,
        }));
      },

      enableEdgeSubscription: async (edgeId) => {
        try {
          const response = await enableEdgeSubscriptionApi(edgeId);
        } catch (error: any) {
          console.error(error);
          throw new Error(error);
        }
      },


      disableEdgeSubscription: async (edgeSubscriptionId) => {
        try {
          const response = await disableEdgeSubscriptionApi(
            edgeSubscriptionId
          );
        } catch (error: any) {
          console.error(error);
          throw new Error(error);
        }
      },

      setCurrentPage: (pageNumber?: number, pageSize?: number) => {
        set( (state) => {
          const newPage: PageInfo = {number: pageNumber?pageNumber:1, size: pageSize?pageSize:12, total: state.edgePage.total};
          return { ...state, edgePage : newPage};
        });
        return;
      },

      setEdgeFilter: (filters?: Record<string, FilterValue | null>) => {
        set( (state) => {
          return { ...state, edgeFilter : filters};
        });
        return;
      },

      setEdgeSorter: (sorters?: SorterResult<DataType> | SorterResult<DataType>[]) => {
        set( (state) => {
          return { ...state, edgeSorter : sorters};
        });
        return;
      },
      

      setPreferenceColumns: (columns: string[]) => {
        set((state) => ({
          ...state,
          preferenceColumns: [...columns],
        }));
      },

      setEdgeSearchTextMap: (edgeSearchTextMap: Map<string, string>) => {
        set((state) => ({
          ...state,
          edgeSearchTextMap: edgeSearchTextMap,
        }));
      },

    }),
    { name: EdgeStore })
);


export const useEdgeGlobalStore = create<EdgeGlobalStore>()(
  devtools(
    persist((set, get) => ({
      totalEdgesCount: 0,
      edges: [],
      edgeEndpointsMap: {} as Record<string, string>,
      
      getEdges: async () => {
        try {
          let infraType = undefined;
          if (useAppConfigStore.getState().isProduction) {
            infraType = EdgeInfraType.Amazon
          }
          const [edges, totalCount] = await getEdgesApi(false, undefined, undefined, infraType, undefined, undefined, undefined);
          
          const edgeEndpoints = {} as Record<string, string>;
          edges.forEach((edge: Edge) => {
            if (edge.endpoint != "") {
              edgeEndpoints[edge.id] = edge.endpoint;
            }
          });
          
          set((state) => ({
            ...state,
            edges: [...edges],
            totalEdgesCount: totalCount,
            edgeEndpointsMap: edgeEndpoints,
          }));
          return edges;
        } catch (error: any) {
          console.error(error);
          throw new Error(error);
        }
      },

      clearEdges: () => {
        set((state) => ({
          edges: [],
          totalEdgesCount: 0
        }));
      },

      getEdgeEndpoint: async (edgeId) => {
        try {
          const edgeEndpoint = Object.entries(get().edgeEndpointsMap).find(([id, _]) => (id == edgeId));
          if (edgeEndpoint) {
            return edgeEndpoint[1];
          }
          const edge = await getEdgeEndpointApi(edgeId);
          set((state) => {
            state.edgeEndpointsMap[edge.id] = edge.endpoint;
            return {
              ...state,
            }
          });
          return edge.endpoint;
        } catch (error: any) {
          console.error(error);
          throw new Error(error);
        }
      },
    }),
    {
      name: EdgeGlobalStore, 
      getStorage: () => sessionStorage,
      partialize: (state) => ({edgeEndpointsMap: state.edgeEndpointsMap}),
    }),
    { name: EdgeStore })
);