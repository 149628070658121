
export enum AuthType {
  AuthTypeNoAuth        = "no_auth",
  AuthTypeApiKey        = "api_key",
  AuthTypeBearerToken   = "bearer_token",
  AuthTypeBasic         = "basic",
  AuthTypeOAuth         = "oauth",
  AuthTypeAwsSignature  = "aws_signature"
}

export const AuthTypeMap: Map<AuthType, string>  =  new Map<AuthType, string> (
	[
		[AuthType.AuthTypeNoAuth, "No Auth"], 
		[AuthType.AuthTypeApiKey, "Api Key"], 
		[AuthType.AuthTypeBearerToken, "Bearer Token"], 
		[AuthType.AuthTypeBasic, "Basic"],
		[AuthType.AuthTypeOAuth, "OAuth"],
		[AuthType.AuthTypeAwsSignature, "AWS Signature"]
	]
)

export enum KeyPlacementType {
  KeyPlacementTypeHeader  = "header",
  KeyPlacemenTypeQueryParams  = "query_params"
}

export const KeyPlacementMap: Map<KeyPlacementType, string>  =  new Map<KeyPlacementType, string> (
	[
		[KeyPlacementType.KeyPlacementTypeHeader, "Header"], 
		[KeyPlacementType.KeyPlacemenTypeQueryParams, "Query Parameter"]
	]
)

export interface ApiKey {
	key:  string;
	value:  string;
	placement:  KeyPlacementType;
}

export type BearerToken = string;

export interface BasicAuth {
	username: string;
	password: string;
}

export enum OAuthGrantType {
	OAuthGrantTypeClientCredentials = "client_credentials",
	OAuthGrantTypeAuthorizationCode  = "authorization_code",
	OAuthGrantTypeRefreshToken  = "refresh_token"
}

export const OAuthGrantTypeMap: Map<OAuthGrantType, string>  =  new Map<OAuthGrantType, string> (
	[
		[OAuthGrantType.OAuthGrantTypeClientCredentials, "Client Credentials"], 
		[OAuthGrantType.OAuthGrantTypeAuthorizationCode, "Authorization Code"],
		[OAuthGrantType.OAuthGrantTypeRefreshToken, "Refresh Token"]
	]
)


// AuthStyle represents how requests for tokens are authenticated
// to the server.
export enum OAuthStyleType {
	// AuthStyleAutoDetect means to auto-detect which authentication
	// style the provider wants by trying both ways and caching
	// the successful way for the future.
	AuthStyleAutoDetect  = 0 ,
	// AuthStyleInParams sends the "client_id" and "client_secret"
	// in the POST body as application/x-www-form-urlencoded parameters.
	AuthStyleInParams = 1,
	// AuthStyleInHeader sends the client_id and client_password
	// using HTTP Basic Authorization. This is an optional style
	// described in the OAuth2 RFC 6749 section 2.3.1.
	AuthStyleInHeader = 2,
}

export const OAuthStyleTypeMap: Map<OAuthStyleType, string>  =  new Map<OAuthStyleType, string> (
	[
		[OAuthStyleType.AuthStyleAutoDetect, "Auto Detect"], 
		[OAuthStyleType.AuthStyleInParams, "In Params"],
		[OAuthStyleType.AuthStyleInHeader, "In Header"]
	]
)

export interface OAuthFlowClientCredentials  {
	clientID: string;
	clientSecret: string;
	tokenURL: string;
	audience: string;
	scopes: string[];
	cscopes: string;
	authStyle: OAuthStyleType;
}

export interface OAuthFlowAuthorizeCode {
	clientID: string;
	clientSecret: string;
	tokenURL: string;
	authURL: string;
	redirectURL: string;
	scopes: string[];
	cscopes: string;
	authStyle: OAuthStyleType;
	refreshToken: string;
}

export interface OAuthFlowRefreshToken {
	clientID: string;
	clientSecret: string;
	tokenURL: string;
	authStyle: OAuthStyleType;
	refreshToken: string;
}

export interface OAuth {
	grantType: OAuthGrantType;
	clientCredentials?: OAuthFlowClientCredentials; 
	authorizeCode?: OAuthFlowAuthorizeCode;
	refreshToken?: OAuthFlowRefreshToken;
} 

export interface AWSSignature {
	accessKey: string;
	secretKey: string;
	region:    string;
	service:   string;
}

interface User {
  firstName? : string;
  lastName? : string;
  business?: string;
  tenantId?: string;
  email?: string;
  phone?: string;
  region?: string;
  challenge?: string;
  code?: string;
  isManufacturer?: boolean;
  supportEmail?: string;
  supportPhone?: string;
}

export interface AuthStore{
  user: User;
  initiateSignUp: (    
    business: string,
    email: string,
    firstName: string,
    lastName: string,
    phone: string,
    region: string,
    challenge: string,
    code : string,
    isManufacturer: boolean,
    supportEmail: string,
    supportPhone: string,
  ) => Promise<void>;

  resendOTP: () => Promise<void>;
  signUpValidate: (otp: string) => Promise<void>;
  signUpSetPassword: (password: string) => Promise<void>;
}
