import { AxiosConfig, axiosInterceptor } from "./axios";
import { jsonApiDeserializer} from "./constant"
import { Update, UpdateSettings } from "types/updater";
import { useAppConfigStore } from "store";

const getUpdatesApiPath = () => {
  const appState = useAppConfigStore.getState();
  return `${appState.updatesApiPath}`;
}

const getUpdateSettingsApiPath = () => {
  const appState = useAppConfigStore.getState();
  return `${appState.updatesApiPath}`;
}

const getUpdateSettingsId = () => {
  const appState = useAppConfigStore.getState();
  return `${appState.updateSettingsId}`;
} 

export const getUpdatesApi = async (
  pageNumber: number,
  pageSize: number,
  filters?: string[],
  sorter?: string[],
) : Promise<[Update[], number]> => {
  let url = `${getUpdatesApiPath()}?`

  if (pageNumber)
    url += `&page[number]=${pageNumber}`
  if (pageSize)
    url += `&page[size]=${pageSize}`

  if (sorter && sorter.length > 0) {
    sorter.map((sort) => {
      url += `&sort=${sort}`
    });
  }

  if (filters && filters.length > 0) {
    filters.map((filter) => {
      url += `&filter=${filter}`
    });
  }

  const response = await axiosInterceptor("get",url);
  const updates = jsonApiDeserializer.deserialize(response.data)  as Update[];
  return [updates, response?.data?.meta?.totalCount];
};

export const getUpdateApi = async (updateId?: string) => {
  const config: AxiosConfig = { maxRetries: 5, retryDelay: 2*1000, retryStatusCodes: [403] };
  const response = await axiosInterceptor("get", `${getUpdatesApiPath()}/${updateId}`, null, config);
  return jsonApiDeserializer.deserialize(response.data)  as Update;
};

export const runUpdateNowApi = async () => {
  const response = await axiosInterceptor("post", `${getUpdatesApiPath()}/run-now`);
  return jsonApiDeserializer.deserialize(response.data)  as Update;
};

export const getUpdateSettingsApi = async () => {
  const url = `${getUpdateSettingsApiPath()}/${getUpdateSettingsId()}`;
  const response = await axiosInterceptor("get", url);
  return jsonApiDeserializer.deserialize(response.data) as UpdateSettings;
};