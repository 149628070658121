
export const convertToReadbableUnit = (unit: string, value: number, decimals = 0, attachUnitAsSuffix?: boolean) : string =>{
  if (!value) return "";
  if (unit == "") return value.toString(10)
    else if (unit == "bytes") {
        const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB']

        let i = 0
        
        for (i; value > 1024; i++) {
            value /= 1024;
        }
        if (decimals)  {
            return value.toFixed(decimals)
        }
        return parseFloat(value.toFixed(decimals)) + ' ' + units[i]
    }else if (unit == "seconds") {
        const units = [60, 60, 24, 7, 52];
        const timeUnits = ['secs', 'mins', 'hrs', 'days', 'weeks', 'years'];
        let i = 0;

        while (value > units[i]) {
            value /= units[i++];
        }
        if (decimals)  {
            return value.toFixed(decimals)
        }
        return parseFloat(value.toFixed(decimals)) + ' ' + timeUnits[i];
    }else {
        if (decimals)  {
            return value.toString(10)
        }
        return value.toString(10) + ' ' + (unit ?? '')
    }
  }