import { AxiosResponse } from "axios";
import { UserPreferenecs } from "types";
import { axiosInterceptor } from "./axios";
import { useAppConfigStore } from "store";

const getUserPreferencesApiPath = () => { 
    const appState = useAppConfigStore.getState();  
    return appState.userPreferencesApiPath;
}

// get profile data
export const getPreferencesApi = async () :Promise<AxiosResponse<UserPreferenecs>> => axiosInterceptor("get", `${getUserPreferencesApiPath()}`);
export const setPreferencesApi = async (userPreferences: UserPreferenecs) :Promise<AxiosResponse<any,any>> => axiosInterceptor("put", `${getUserPreferencesApiPath()}`, userPreferences);

