import { useAppConfigStore } from "store";
import { SearchRun, SearchRunAction, WorkflowRun } from "types";
import { axiosInterceptor } from "./axios";
import { jsonApiDeserializer } from "./constant";

export const getWorkflowRunsApi = async (
  pageNumber: number,
  pageSize: number,
  filters?: string[],
  sorter?: string[]): Promise<[WorkflowRun[], number]> => {
  const appState = useAppConfigStore.getState();
  const workflowRunsApiPath = `${appState.workflowRunsApiPath}`;
  
  let url = `${workflowRunsApiPath}?page[number]=${pageNumber}&page[size]=${pageSize}&runLiveStatusCheck=false&fields[workflow-runs]=id,name,user,status,isSubworkflowRun,startTime,closeTime,tagID&include=workflows&fields[workflows]=name`

  if (filters && filters.length > 0) {
    filters.map((filter) => {
      url += `&filter=${filter}`
    });
  }

  if (sorter && sorter.length > 0) {
    sorter.map((sort) => {
      url += `&sort=${sort}`
    });
  }

  const response = await axiosInterceptor("get",url);

  return [jsonApiDeserializer.deserialize(response.data) as WorkflowRun[], response?.data?.meta?.totalCount];
};

export const getSearchRunsApi = async (
  pageNumber: number,
  pageSize: number,
  filters?: string[],
  sorter?: string[]): Promise<[SearchRun[], number]> => {
  const appState = useAppConfigStore.getState();
  const searchRunsApiPath = `${appState.searchRunsApiPath}`;
  
  let url = `${searchRunsApiPath}?page[number]=${pageNumber}&page[size]=${pageSize}`

  if (filters && filters.length > 0) {
    filters.map((filter) => {
      url += `&filter=${filter}`
    });
  }

  if (sorter && sorter.length > 0) {
    sorter.map((sort) => {
      url += `&sort=${sort}`
    });
  }

  const response = await axiosInterceptor("get",url);

  return [jsonApiDeserializer.deserialize(response.data) as SearchRun[], response?.data?.meta?.totalCount];
};

export const getSearchRunActionsApi = async (
  pageNumber: number,
  pageSize: number,
  filters?: string[],
  sorter?: string[]): Promise<[SearchRunAction[], number]> => {
  const appState = useAppConfigStore.getState();
  const searchRunActionsApiPath = `${appState.searchRunActionsApiPath}`;
  let url = `${searchRunActionsApiPath}?page[number]=${pageNumber}&page[size]=${pageSize}`

  if (filters && filters.length > 0) {
    filters.map((filter) => {
      url += `&filter=${filter}`
    });
  }

  if (sorter && sorter.length > 0) {
    sorter.map((sort) => {
      url += `&sort=${sort}`
    });
  }

  const response = await axiosInterceptor("get",url);

  return [jsonApiDeserializer.deserialize(response.data) as SearchRunAction[], response?.data?.meta?.totalCount];
};
