import { PropsWithChildren } from "react";
import { theme, ConfigProvider } from "antd";

const getTextColor = (hex: string) => {
  const [r, g, b] = hex.match(/\w\w/g)!.map(x => parseInt(x, 16));
  const luminance = 0.299 * r + 0.587 * g + 0.114 * b;
  return luminance > 127 ? '#000000' : '#FFFFFF';
};

export const ThemeManager = (props: PropsWithChildren) => {
  const { token } = theme.useToken();
  const textColor = getTextColor(token.colorPrimary);

  return (
    <ConfigProvider
      theme={{
        token: {
          colorLink: token.colorPrimary,
          colorLinkHover: token.colorPrimaryTextHover,
          colorLinkActive: token.colorPrimaryActive,
          fontFamily: "Open Sans*",
          colorTextLightSolid: textColor,
          colorBgSpotlight: token.colorPrimary,
        },
        components: {
          Menu: {
            itemSelectedBg: token.colorPrimary,
            itemSelectedColor: textColor,
          },
          Modal: {
            wireframe: true
          },
        },
      }}
    >
      {props.children}
    </ConfigProvider>
  );
};
