import { Empty, Space, theme, Typography } from "antd";
import { Tooltip as AntdToolTip } from "antd";
import {
  Cell,
  Pie,
  PieChart,
  ResponsiveContainer,
  Sector,
  Tooltip,
} from "recharts";
import { PieData, BigScreenQuery } from "types";

import { useMediaQuery } from "react-responsive";

import {
  getContainerStyle,
  getHeadingContainerStyle,
  getHeadingTopLineStyle,
} from "./KpiStyles";

import React, { CSSProperties, useCallback, useState } from "react";
import { useRbacStore, useSettingsStore } from "store";
import { convertToReadbableUnit } from "utility/metrics";
const { Text } = Typography;

export interface KpiPieProps {
  id: string;
  data: PieData;
  colors: string[];
  height?: string;
}

export const KpiPie = (props: KpiPieProps) => {
  const { token } = theme.useToken();
  const isBigScreen = useMediaQuery(BigScreenQuery);
  const { mode } = useSettingsStore((state) => ({
    mode: state.lightMode,
  }));
  const containerStyle = getContainerStyle({
    background: token.colorBgContainer,
    width: "100%",
    height: props.height ? props.height : isBigScreen ? "250px" : "150px",
    marginLeft: "5px",
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
  });
  const headingtextStyle = {
    fontSize: token.fontSizeSM,
    marginTop: token.marginXS,
    marginLeft: token.marginXXS,
    opacity: 0.7,
    lineHeight: 1.1,
    maxWidth: isBigScreen ? "150px" : "130px",
  };

  const { users } = useRbacStore((state) => ({
    users: state.users,
  }));

  const headingContainerStyle = getHeadingContainerStyle();
  const topLine: CSSProperties = getHeadingTopLineStyle();
  const [activeIndex, setActiveIndex] = useState(0);
  const onPieEnter = useCallback(
    (_: any, index: any) => {
      setActiveIndex(index);
    },
    [setActiveIndex]
  );

  const renderActiveShape = (renderProps: any) => {
    const RADIAN = Math.PI / 180;
    const {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      value,
    } = renderProps;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 5) * cos;
    const sy = cy + (outerRadius + 5) * sin;
    const mx = cx + (outerRadius + 5) * cos;
    const my = cy + (outerRadius + 5) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? "start" : "end";

    return (
      <g>
        <text
          x={cx}
          y={cy}
          dy={8}
          style={{ fontWeight: "bold", fontSize: token.fontSizeXL }}
          textAnchor="middle"
          fill={mode ? "#282876" : "#FFFFFF"}
        >
          {convertToReadbableUnit(
            props.data.total.unit,
            props.data.total.value
          )}
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 3}
          outerRadius={outerRadius + 7}
          fill={fill}
        />
        {isBigScreen && (
          <path
            d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
            stroke={fill}
            fill="none"
          />
        )}
        {isBigScreen && (
          <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
        )}
        {isBigScreen && (
          <text
            x={ex + (cos >= 0 ? 1 : -1) * 5}
            y={ey}
            textAnchor={textAnchor}
            fill="#999"
          >
            {convertToReadbableUnit(props.data.primary.unit, value)}
          </text>
        )}
      </g>
    );
  };

  const getCustomX = () => { 
    if(props?.id == "cases-total-by-users-pie"){
      const dto = users[props.data.primary.values[activeIndex]?.x]
      if(dto){
        return `${dto.firstName} ${dto.lastName}`
      }
    }
    return props.data.primary.values[activeIndex]?.x;
  }

  

  const CustomTooltip = (activeIndex: number) => {
    return (
      <div
        style={{
          backgroundColor: token.colorPrimaryBg,
          textAlign: "center",
          borderRadius: token.borderRadius,
        }}
      >
        <AntdToolTip placement="top">
          <Space size={1} direction="vertical">
            <Text
              style={{
                marginLeft: token.marginXS,
                marginRight: token.marginXS,
              }}
            >
              {getCustomX()}
            </Text>
            <Text
              strong
              style={{
                color: mode ? "#282876" : "#FFFFFF",
              }}
            >
              {convertToReadbableUnit(
                props.data.primary.unit,
                props.data.primary.values[activeIndex]?.y
              )}
            </Text>
          </Space>
        </AntdToolTip>
      </div>
    );
  };
  
  return (
    <div id="kpi-pie-container" style={containerStyle}>
      <div style={topLine}>
        <div style={headingContainerStyle}>
          <Text strong ellipsis style={headingtextStyle}>
            {props.data.primary.text}
          </Text>
        </div>
      </div>
      {props.data.primary.values.length != 0 && (
        <ResponsiveContainer width={"100%"} height={"100%"}>
          <PieChart
            data={props.data.primary.values.map((point) => {
              return { x: point.x, y: point.y };
            })}
          >
            <Pie
              activeIndex={activeIndex}
              activeShape={renderActiveShape}
              data={props.data.primary.values}
              cx="50%"
              cy="50%"
              innerRadius={isBigScreen ? 60 : 40}
              outerRadius={isBigScreen ? 80 : 50}
              fill={token.colorPrimary}
              dataKey="y"
              nameKey="x"
              onMouseEnter={onPieEnter}
              stroke="none"
              isAnimationActive={false}
              //label={renderCustomizedLabel}
            >
              {props.data.primary.values.map((v, i) => (
                <Cell key={`cell-${i}`} fill={props.colors[i]} />
              ))}
            </Pie>
            <Tooltip content={CustomTooltip(activeIndex)} />
          </PieChart>
        </ResponsiveContainer>
      )}
      {props.data.primary.values.length == 0 && (
        <Empty style={{ margin: isBigScreen ? "20%": ""}} />
      )}
    </div>
  );
};
