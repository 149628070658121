
import jwtDecode, {JwtPayload} from "jwt-decode";

interface PreferenceClaims {
  zs_light_mode : boolean, 
  zs_light_mode_primary_color : string,
  zs_dark_mode_primary_color : string
}

interface TokenStatus {
  isAuthenticated: boolean;
  isSso: boolean;
  subject: string ;
  expiry: number;
  lightMode?: boolean;
  lightModePrimaryColor?: string;
  darkModePrimaryColor?: string;
}



export class TokenUtils {
  public static getTokenStatus = (token: string | null , issuer : string | null) : TokenStatus => {
    if ( !token ) return {isAuthenticated: false, isSso: false, subject : "", expiry : 0};
    if ( !issuer ) return {isAuthenticated: false, isSso: false, subject : "", expiry : 0};
    const decoded : JwtPayload & PreferenceClaims = jwtDecode(token);          
    const leeway = 1000; // Allow for 1 second leeway/ skew in token expiry
    const isAuthenticated = decoded.exp && decoded.exp * 1000 - leeway > Date.now() ? true : false;
    const isSso =(decoded.iss && decoded.iss != issuer) ? true : false;
    const lightMode = decoded.zs_light_mode;
    const lightModePrimaryColor = decoded.zs_light_mode_primary_color;
    const darkModePrimaryColor = decoded.zs_dark_mode_primary_color;
    return {isAuthenticated, isSso, subject : decoded.sub ?? "", expiry : decoded.exp ?? 0, lightMode, lightModePrimaryColor, darkModePrimaryColor};
  }
}
