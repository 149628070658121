import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { TENANT_ID_CLAIM, USER_ID_CLAIM, GROUP_PREFIX } from "types";

import { Modal, notification, Select, Typography, Spin } from "antd";
import { getUserApiWithToken } from "api/rbac";
import { AxiosResponse } from "axios";
import { useAppConfigStore, useOrganizationStore } from "store";
import { useTokenStore } from "store/token";
import { UserDto, UserTagDto } from "types";
import { setupContext } from "utility";
import styles from "./Callback.module.scss";

const { Text } = Typography;
const Callback = () => {
  const mounted = useRef(false);
  const nagivate = useNavigate();
  const setToken = useTokenStore((state) => state.setToken);

  const activateContext = useOrganizationStore(
    (state) => state.activateContext
  );
  const clearContextRequest = useOrganizationStore(
    (state) => state.clearContextRequest
  );
  const token = useTokenStore((state) => state.token);
  const state = useTokenStore((state) => state.state);
  const auth = useAppConfigStore((state) => state.auth);
  const keycloak = useAppConfigStore((state) => state.keycloak);
  const isProduction = useAppConfigStore((state) => state.isProduction);
  const isSelfHosted = useAppConfigStore((state) => state.isSelfHosted);
  const isCloud = useAppConfigStore((state) => state.isCloud);

  const [selections, setSelections] = useState<UserTagDto[]>([]);
  const [selectedContext, setSelectedContext] = useState<string | null>(null);
  const [openModal, setOpenModal] = useState(false);

  const processToken = async (JWT: string) => {
    const payload = JWT.split(".")[1];
    const decoded = JSON.parse(atob(payload));
    const scopes: string[] = decoded.scope?.split(" ") || [];
    const tenantId = decoded[TENANT_ID_CLAIM];
    const userId = decoded[USER_ID_CLAIM];
    const prefix = GROUP_PREFIX;
    const context = scopes
      .find((scope) => scope.startsWith(prefix))
      ?.replace(prefix, "");
    if (context) {
      setToken(JWT);
      activateContext(context);
      clearContextRequest();
    } else {
      /** Invoke user api to get logged in user details */
      const response: AxiosResponse<UserDto> = await getUserApiWithToken(
        userId,
        JWT
      );
      const user = response.data;
      const needSelect = user.heldTags?.length > 1;
      if (!needSelect) {
        console.log(`Setting context ${JSON.stringify(user.heldTags)}`);
        const ctx = user.heldTags?.[0]?.id || `${tenantId}_tag`;
        setupContext({
          context: ctx,
          environment: isProduction ? "prod" : "dev",
          setToken,
          activateContext,
          clearContextRequest,
          silent: true,
          auth: auth || keycloak,
          selfHosted: isSelfHosted,
        });
      } else {
        setSelections(user.heldTags);
        setSelectedContext(user.heldTags[0].id);
        setOpenModal(true);
      }
    }
  };

  const initCloud = async () => {
    if (!state) {
      notification.error({
        message: "State not found in callback",
        duration: 6,
      });
      console.log("State not found in callback");
      return;
    }

    if (!auth) {
      console.log("Auth not found in app state");
      return;
    }

    auth.parseHash(async (err, authResult) => {
      if (err) return;
      else {
        if (authResult?.accessToken) {
          const JWT = authResult.accessToken;
          if (state === authResult.state) {
            await processToken(JWT);
          } else {
            console.log("State mismatch during auth, cannot set token");
            notification.error({
              message: "State mismatch during auth, cannot set token",
              duration: 6,
            });
          }
        } else {
          console.log("Failed to get access token");
          notification.error({
            message: "Failed to get access token",
            duration: 6,
          });
        }
      }
    });
  };

  const initOnPrem = async () => {
    console.log("Init on prem");
    if (keycloak) {
      console.log("Keycloak found");
      const authenticated = await keycloak.init({
        onLoad: "check-sso",
      });

      if (authenticated && keycloak.token) {
        const JWT = keycloak.token;
        await processToken(JWT);
        console.log("Navigating to dashboard");
        nagivate("/dashboard");
      } else {
        console.log("Keycloak not authenticated");
        notification.error({
          message: "Keycloak not authenticated",
          duration: 6,
        });
      }
    }
  };

  useEffect(() => {
    if (isSelfHosted) {
      initOnPrem();
    }
    if (isCloud) {
      initCloud();
    }
  }, [state, isCloud, isSelfHosted]);

  useEffect(() => {
    if (!isSelfHosted) {
      mounted.current
        ? nagivate(token ? "/home" : "/signin")
        : (mounted.current = true);
    }
  }, [token]);

  return (
    <div>
      <Modal
        title="Select Context"
        open={openModal}
        closable={false} // Set closable to false to prevent dismissal
        cancelButtonProps={{ style: { display: "none" } }}
        onOk={() => {
          if (selectedContext && auth)
            setupContext({
              context: selectedContext,
              environment: isProduction ? "prod" : "dev",
              setToken,
              activateContext,
              clearContextRequest,
              silent: true,
              auth: auth,
              selfHosted: isSelfHosted,
            });
          setOpenModal(false);
        }}
      >
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              minWidth: "300px",
              gap: "10px",
            }}
          >
            <Text>Select Active Context To Login </Text>
            <Select
              options={selections.map((tag: UserTagDto) => ({
                label: tag.value,
                value: tag.id,
              }))}
              onSelect={(value) => setSelectedContext(value.toString())}
              value={selectedContext}
              style={{ minWidth: "200px" }}
            ></Select>
          </div>
        </div>
      </Modal>
      <div className={styles.wrapper}>
        <div className={styles.container}>
          {!openModal && (
            <Modal open={!openModal} footer={null} closable={false} centered>
              <div
                style={{
                  textAlign: "center",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Text>Authenticating...</Text>
                <Spin spinning={true} size="large" />
              </div>
            </Modal>
          )}
        </div>
      </div>
    </div>
  );
};

export default Callback;
