import { FC, useState, useEffect, useRef } from "react";
import {
  theme,
  Typography,
  Space,
  Badge,
  Image,
  Tag,
  Card,
  Carousel,
  Popover,
  Tooltip,
  Button,
  Modal as AntdModal,
  Flex
} from "antd";
import {
  WarningOutlined,
} from "@ant-design/icons";
import { CarouselRef } from 'antd/es/carousel';
import { useOrganizationStore, useSearchStore, useSettingsStore } from "store";
import { SearchRunResultMeta, SearchRunStatus } from "types";
import { getAvatarColor, getTagColor } from "utility";
import styled from 'styled-components';
import { getSubHeadingMessageForSearchResults } from "./Utils";
import { getAppImagePathApi } from "api";
const { Text } = Typography;

interface CarouselItem {
  rm: SearchRunResultMeta;
}

export interface SummaryBannerProps {
  filteredTags: string[];
  filteredAppSubscriptions: string[];
}

export const SummaryBanner: FC<SummaryBannerProps> = ({
  filteredTags,
  filteredAppSubscriptions,
}) => {
  const { token } = theme.useToken();
  const carouselRef = useRef<CarouselRef>(null);
  const [carouselItems, setCarouselItems] = useState<CarouselItem[]>([]);
  const { lightMode } = useSettingsStore((state) => ({
    lightMode: state.lightMode,
  }));
  const [appSubscriptionHoverId, setAppSubscriptionHoverId] = useState("");

  const [jumpToModal, setJumpToModal] = useState<boolean>(false);
  const { activeSearchAppTabIndex, currentSearchRun, setActiveSearchAppTab } = useSearchStore((state) => ({
    activeSearchAppTabIndex: state.activeSearchAppTabIndex,
    currentSearchRun: state.currentSearchRun,
    setActiveSearchAppTab: state.setActiveSearchAppTab,
  }));

  const {
    mspEnabled,
    tags
  } = useOrganizationStore((state) => ({
    mspEnabled: state.mspEnabled,
    tags: state.tags
  }));

  const StyledCarousel = styled(Carousel)`
    .slick-dots li button {
      border-radius: 0;
      width: 20px;
      height: 3px;
      margin-top: 5px;
      transition: background-color 0.3s ease, opacity 0.3s ease; /* Smooth transition */
    }
    .slick-dots li button:hover {
      background-color: ${token.colorPrimaryHover};
      opacity: 1;  
    }
    .slick-dots li.slick-active button {
      background-color: ${token.colorPrimaryActive};
    }
    .slick-dots li:not(.slick-active) button {
      background-color: #ccc;
      opacity: 0.7;
    }
    .slick-next  {
      color: ${token.colorText};
    }
    .slick-prev  {
      color: ${token.colorText};
    }
  `;

  const goToSlide = (index: number) => {
    if (carouselRef.current) {
      carouselRef.current.goTo(index); // Access the goTo method
    }
  };

  const syncCarouselItems = async () => {
    const items: CarouselItem[] = [];
    if (!currentSearchRun.resultMetas) {
      setCarouselItems([]);
      return;
    }

    for (const rm of currentSearchRun.resultMetas
      .sort((a, b) => (a.appName > b.appName ? -1 : 1))
      .sort((a, b) => a.count > b.count ? -1 : 1)
      .filter(
        (rm) =>
          (filteredAppSubscriptions.length
            ? !filteredAppSubscriptions.includes(rm.appSubscriptionID)
            : true) &&
          (filteredTags.length
            ? !filteredTags.includes(`${rm.tenantID}_tag`)
            : true)
      )
    ) {
      items.push({ rm: rm } as CarouselItem);
    }

    if (items.length && activeSearchAppTabIndex == -1) {
      setActiveSearchAppTab(0);
    }
    if (!items.length) setActiveSearchAppTab(-1);
    setCarouselItems(items)
    goToSlide(activeSearchAppTabIndex);
  }

  const getCarouselItem = (ci: CarouselItem, showFull: boolean) => {
    return (
      <Space
        direction={"horizontal"}
        size={showFull ? token.sizeMS: 0}
      >
        {showFull && <Image
          preview={false}
          draggable={false}
          width={"90px"}
          height={"30px"}
          src={getAppImagePathApi(ci.rm.appID, lightMode)}
          style={{ marginTop: "4px" }}
          />
        }
        <Tag color={getAvatarColor(`${ci.rm.appName}(${ci.rm.appSubscriptionName})`)}>
          {ci.rm.appName}({ci.rm.appSubscriptionName})
        </Tag>
        {`${ci.rm.tenantID}_tag` && mspEnabled &&
          <Tag color={getTagColor(`${ci.rm.tenantID}_tag`)}>
            {tags?.find((tag) => (tag.id == `${ci.rm.tenantID}_tag`))?.value}
          </Tag>
        }
        {showFull ? (
          ci.rm.resultStatus != SearchRunStatus.Completed ? (
            <Popover
              content={
                `Search failed using app "${ci.rm.appName}" and configuration "${ci.rm.appSubscriptionName}". Check configuration details`
              }
            >
              <WarningOutlined style={{ color: token.colorWarning }} />
            </Popover>
          ) : (
            <Badge
              count={ci.rm.count}
              overflowCount={9999}
              showZero
              color={token.colorText}
              size="default"
            />
          )
        ):(null)
        }
      </Space>
    )
  }

  const getJumpToModal = () => {
    return (
      <AntdModal
        title={"Jump To"}
        width={800}
        open={true}
        onCancel={() => setJumpToModal(false)}
        footer={null}
      >
        <Flex gap="4px 4px" wrap="wrap" align={"left"}>
          {carouselItems.map((ci, index) => (
            <Card
              size={"small"}
              key={ci.rm.appSubscriptionID}
              onClick={(e) => { setActiveSearchAppTab(index); goToSlide(index) }}
              style={{ cursor: "pointer" }}
              onMouseOver={() => setAppSubscriptionHoverId(ci.rm.appSubscriptionID)}
              onMouseOut={() => setAppSubscriptionHoverId("")}
              styles={{
                body: {
                  alignContent: "center",
                  justifyContent: "center",
                  borderRadius: token.borderRadius,
                  background: token.colorPrimaryBg
                }
              }}
            >
              {getCarouselItem(ci, false)}
            </Card>
          ))}
        </Flex>

      </AntdModal>

    );
  }

  const getSummaryBanner = () => {
    return (
      <Space>
        {activeSearchAppTabIndex != -1 &&
          <Tooltip title={"Jump To"}>
            <Button
              shape="circle"
              size="small"
              style={{ position: "absolute", right: 3, top: 2 }}
              color={token.colorText}
              onClick={() => setJumpToModal(true)}
            >
              {activeSearchAppTabIndex + 1}
            </Button>
          </Tooltip>
        }
        <Text style={{ fontSize: "20", fontWeight: "normal", color: token.colorText }}>
          {getSubHeadingMessageForSearchResults(currentSearchRun)}
        </Text>
      </Space>
    );
  };

  const renderCarouselContainer = (childerns: any) => {
    return (
      <Card
        size={childerns ? "small" : "default"}
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          borderRadius: token.borderRadius,
          background: token.colorPrimaryBg,
        }}
        styles={{
          header: {
            background: token.colorPrimaryBg,
            marginBottom: "-15px",
            textAlign: "center"
          },
          body: {
            alignContent: "center",
            textAlign: "center",
            justifyContent: "center",
            borderRadius: token.borderRadius,
            background: token.colorPrimaryBg,
            minHeight: childerns ? "80px" : undefined
          }
        }}
        title={getSummaryBanner()}
      >
        {childerns}
      </Card>
    )
  }

  useEffect(() => {
    syncCarouselItems();
  }, [currentSearchRun, filteredAppSubscriptions, filteredTags]);

  return (
    <div id="summary-banner-container">
      {jumpToModal && getJumpToModal()}
      {carouselItems.length ? (
        <StyledCarousel
          afterChange={(number) => { setActiveSearchAppTab(number); }}
          arrows={carouselItems.length != 0}
          // infinite={false}
          dotPosition={"bottom"}
          fade
          touchMove
          ref={carouselRef}
          initialSlide={activeSearchAppTabIndex == -1 ? undefined : activeSearchAppTabIndex}
        >
          {carouselItems.map((ci, index) => (
            renderCarouselContainer(getCarouselItem(ci, true))
          ))}
        </StyledCarousel>
      ) : (
        renderCarouselContainer(undefined)
      )}
    </div>
  )
}