import React, { useState } from "react";
import { Form, Input, Button, Select, Steps, Radio } from "antd";
import { RegistrationData } from "types/register";
import { registrationApi } from "api/register";
import { set } from "lodash";

const { TextArea } = Input;
const { Option } = Select;
const { Step } = Steps;

const passwordRules = [
  {
    required: true,
    message: "Password is required.",
  },
  {
    pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.{12,})/,
    message:
      "Password must be at least 12 characters long, include one uppercase, one lowercase, and one special character.",
  },
];

export const Registration = () => {
  const [form] = Form.useForm();
  const [busy, setBusy] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [showSupportPassword, setShowSupportPassword] = useState(false);
  const [showPrimaryPassword, setShowPrimaryPassword] = useState(false);
  const [showEmailPassword, setShowEmailPassword] = useState(false);
  const [useSMTP, setUseSMTP] = useState(false);

  const validateCurrentStep = async () => {
    try {
      await form.validateFields();
      setCurrentStep(currentStep + 1);
    } catch (error) {
      console.log("Validation failed:", error);
    }
  };

  const onFinish = async (values: any) => {
    const data: RegistrationData = {
      accessCode: values.accessCode,
      domain: values.domain,
      certificate: values.certificate,
      privateKey: values.privateKey,
      supportPassword: values.supportPassword,
      useSMTP: values.useSMTP,
      startTls: values.startTls,
      host: values.host,
      port: values.port,
      user: values.user,
      password: values.password,
      ssl: values.ssl,
      from: values.from,
      fromDisplayName: values.fromDisplayName,
      replyTo: values.replyTo,
      replyToDisplayName: values.replyToDisplayName,
      envelopeFrom: values.envelopeFrom,
      primaryUserPassword: values.primaryUserPassword,
      primaryUserFirstName: values.primaryUserFirstName,
      primaryUserLastName: values.primaryUserLastName,
      primaryUserPhone: values.primaryUserPhone,
      primaryUserRegion: values.primaryUserRegion,
      primaryUserTitle: values.primaryUserTitle,
      businessName: values.businessName,
      businessDescription: values.businessDescription,
      authKey: values.authKey,
    };
    try {
      setBusy(true);
      await registrationApi(data);
      console.log("Registration successful!");
    } catch (error) {
      console.error("Registration failed:", error);
    } finally {
      setBusy(false);
    }
  };

  const steps = [
    {
      title: "Registration Information",
      content: (
        <>
          <Form.Item
            name="accessCode"
            label="Access Code"
            rules={[
              {
                required: true,
                message: "Access code is required.",
              },
              {
                pattern: /^[A-Z0-9]{6}$/,
                message:
                  "Access code must be 6 alphanumeric characters, all uppercase.",
              },
            ]}
          >
            <Input placeholder="Enter your access code" maxLength={6} />
          </Form.Item>
          <Form.Item name="domain" label="Domain">
            <Input placeholder="Enter the internal domain" />
          </Form.Item>
          <Form.Item name="certificate" label="Certificate with SAN">
            <TextArea
              placeholder="Paste the certificate in PEM format"
              rows={4}
            />
          </Form.Item>
          <Form.Item name="privateKey" label="Private Key">
            <TextArea
              placeholder="Paste the private key in PEM format"
              rows={4}
            />
          </Form.Item>
          <Form.Item
            name="supportPassword"
            label="Support Password"
            rules={passwordRules}
          >
            <Input.Password
              placeholder="Enter support password"
              visibilityToggle={{
                visible: showSupportPassword,
                onVisibleChange: setShowSupportPassword,
              }}
            />
          </Form.Item>
          <Form.Item
            name="confirmSupportPassword"
            label="Confirm Support Password"
            dependencies={["supportPassword"]}
            rules={[
              {
                required: true,
                message: "Please confirm your support password.",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("supportPassword") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error("Passwords do not match!"));
                },
              }),
            ]}
          >
            <Input.Password placeholder="Confirm support password" />
          </Form.Item>
        </>
      ),
    },
    {
      title: "Email Setup",
      content: (
        <>
          <Form.Item name="useSMTP" label="Configure SMTP Settings?">
            <Radio.Group onChange={(e) => setUseSMTP(e.target.value === "yes")}>
              <Radio value="yes">Yes</Radio>
              <Radio value="no">No</Radio>
            </Radio.Group>
          </Form.Item>
          {useSMTP && (
            <>
              <Form.Item
                name="startTls"
                label="Start TLS"
                valuePropName="checked"
              >
                <Radio.Group>
                  <Radio value={true}>Enabled</Radio>
                  <Radio value={false}>Disabled</Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                name="host"
                label="SMTP Host"
                rules={[{ required: true, message: "SMTP host is required." }]}
              >
                <Input placeholder="Enter SMTP host" />
              </Form.Item>
              <Form.Item
                name="port"
                label="SMTP Port"
                rules={[{ required: true, message: "SMTP port is required." }]}
              >
                <Input type="number" placeholder="Enter SMTP port" />
              </Form.Item>
              <Form.Item
                name="user"
                label="SMTP User"
                rules={[{ required: true, message: "SMTP user is required." }]}
              >
                <Input placeholder="Enter SMTP user" />
              </Form.Item>
              <Form.Item
                name="password"
                label="SMTP Password"
                rules={passwordRules}
              >
                <Input.Password
                  placeholder="Enter SMTP password"
                  visibilityToggle={{
                    visible: showEmailPassword,
                    onVisibleChange: setShowEmailPassword,
                  }}
                />
              </Form.Item>
              <Form.Item
                name="confirmSMTPPassword"
                label="Confirm SMTP Password"
                dependencies={["password"]}
                rules={[
                  {
                    required: true,
                    message: "Please confirm your SMTP password.",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error("Passwords do not match!")
                      );
                    },
                  }),
                ]}
              >
                <Input.Password placeholder="Confirm SMTP password" />
              </Form.Item>
              <Form.Item name="ssl" label="SSL" valuePropName="checked">
                <Radio.Group>
                  <Radio value={true}>Enabled</Radio>
                  <Radio value={false}>Disabled</Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                name="from"
                label="From Address"
                rules={[
                  { required: true, message: "From address is required." },
                ]}
              >
                <Input placeholder="Enter from address" />
              </Form.Item>
              <Form.Item name="fromDisplayName" label="From Display Name">
                <Input placeholder="Enter from display name" />
              </Form.Item>
              <Form.Item name="replyTo" label="Reply-To Address">
                <Input placeholder="Enter reply-to address" />
              </Form.Item>
              <Form.Item
                name="replyToDisplayName"
                label="Reply-To Display Name"
              >
                <Input placeholder="Enter reply-to display name" />
              </Form.Item>
              <Form.Item name="envelopeFrom" label="Envelope From">
                <Input placeholder="Enter envelope from" />
              </Form.Item>
            </>
          )}
        </>
      ),
    },
    {
      title: "User Setup",
      content: (
        <>
          <Form.Item
            name="primaryUserPassword"
            label="Primary User Password"
            rules={passwordRules}
          >
            <Input.Password
              placeholder="Enter primary user password"
              visibilityToggle={{
                visible: showPrimaryPassword,
                onVisibleChange: setShowPrimaryPassword,
              }}
            />
          </Form.Item>
          <Form.Item
            name="confirmPrimaryUserPassword"
            label="Confirm Primary User Password"
            dependencies={["primaryUserPassword"]}
            rules={[
              {
                required: true,
                message: "Please confirm your primary user password.",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (
                    !value ||
                    getFieldValue("primaryUserPassword") === value
                  ) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error("Passwords do not match!"));
                },
              }),
            ]}
          >
            <Input.Password placeholder="Confirm primary user password" />
          </Form.Item>
          <Form.Item
            name="primaryUserFirstName"
            label="Primary User First Name"
            rules={[{ required: true, message: "First name is required." }]}
          >
            <Input placeholder="Enter first name" />
          </Form.Item>
          <Form.Item
            name="primaryUserLastName"
            label="Primary User Last Name"
            rules={[{ required: true, message: "Last name is required." }]}
          >
            <Input placeholder="Enter last name" />
          </Form.Item>
          <Form.Item
            name="primaryUserPhone"
            label="Primary User Phone"
            rules={[
              {
                required: true,
                message: "Phone number is required.",
              },
              {
                pattern: /^[0-9]{10}$/,
                message: "Phone number must be 10 digits.",
              },
            ]}
          >
            <Input placeholder="Enter phone number" maxLength={10} />
          </Form.Item>
          <Form.Item
            name="primaryUserRegion"
            label="Primary User Region"
            rules={[{ required: true, message: "Region is required." }]}
          >
            <Input placeholder="Enter region" />
          </Form.Item>
          <Form.Item
            name="primaryUserTitle"
            label="Primary User Title"
            rules={[{ required: true, message: "Job title is required." }]}
          >
            <Input placeholder="Enter job title" />
          </Form.Item>
        </>
      ),
    },
    {
      title: "Business Details",
      content: (
        <>
          <Form.Item
            name="businessName"
            label="Business Name"
            rules={[{ required: true, message: "Business name is required." }]}
          >
            <Input placeholder="Enter business name" />
          </Form.Item>
          <Form.Item
            name="businessDescription"
            label="Business Description (Vertical)"
            rules={[
              { required: true, message: "Business description is required." },
            ]}
          >
            <Select placeholder="Select industry vertical">
              <Option value="technology">Technology</Option>
              <Option value="finance">Finance</Option>
              <Option value="healthcare">Healthcare</Option>
              <Option value="retail">Retail</Option>
              <Option value="manufacturing">Manufacturing</Option>
              <Option value="education">Education</Option>
              <Option value="other">Other</Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="authKey"
            label="Auth Key from System"
            rules={[{ required: true, message: "Auth key is required." }]}
          >
            <Input placeholder="Enter auth key" />
          </Form.Item>
        </>
      ),
    },
  ];

  return (
    <>
      <Steps current={currentStep}>
        {steps.map((step, index) => (
          <Step key={index} title={step.title} />
        ))}
      </Steps>
      <Form
        form={form}
        name="registration-form"
        layout="vertical"
        onFinish={onFinish}
        style={{ maxWidth: "600px", margin: "20px auto" }}
      >
        {steps[currentStep].content}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "20px",
          }}
        >
          {currentStep > 0 && (
            <Button onClick={() => setCurrentStep(currentStep - 1)}>
              Previous
            </Button>
          )}
          {currentStep < steps.length - 1 ? (
            <Button type="primary" onClick={validateCurrentStep}>
              Next
            </Button>
          ) : (
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          )}
        </div>
      </Form>
    </>
  );
};
