import { AxiosResponse } from "axios";
import { axiosInterceptor } from "./axios";
import { Goal, Metric, GoalCreateRequest, GoalList, GoalUpdateRequest } from "types";
import {  jsonApiDeserializer } from "./constant";
import { useAppConfigStore } from "store";
const getGoalsApiPath = () => {
  const appState = useAppConfigStore.getState();
  return appState.goalsApiPath;
}

export const listGoalsApi = async (): Promise<AxiosResponse<GoalList>> => axiosInterceptor("get", `${getGoalsApiPath()}`);
export const getGoalApi = async (id: string): Promise<AxiosResponse<Goal>> => axiosInterceptor("get", `${getGoalsApiPath()}/${id}`);
export const createGoalApi = async (goal: GoalCreateRequest): Promise<AxiosResponse<Goal>> => axiosInterceptor("post", `${getGoalsApiPath()}`, { ...goal });
export const updateGoalApi = async (id: string, goal: GoalUpdateRequest): Promise<AxiosResponse<Goal>> => axiosInterceptor("patch", `${getGoalsApiPath()}/${id}`, { ...goal });
export const deleteGoalApi = async (id: string): Promise<AxiosResponse<void>> => axiosInterceptor("delete", `${getGoalsApiPath()}/${id}`);

export const getGoalMetricsApi = async () : Promise<Metric> => {   
  const appState = useAppConfigStore.getState();
  const metricsApiPath = appState.metricsApiPath;
  const url = `${metricsApiPath}/goal-values-total`
  const response = await axiosInterceptor("get", url);
  const metric = jsonApiDeserializer.deserialize(response.data) as Metric;
  return metric;
}



