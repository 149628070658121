
import { FC, useEffect, useState } from "react";
import {
  Typography,
  Popover,
  Tooltip,
  List,
} from "antd";

import { useMediaQuery } from "react-responsive";
import {  BigScreenQuery } from "types";
import { JsonViewer } from '@textea/json-viewer'
import { CodeBlock, atomOneDark, atomOneLight } from "react-code-blocks";

import { HLink } from "components/Link";
import { TextType } from "types";
import { useSettingsStore } from "store";

const { Text } = Typography;

export interface ColumnRenderProps {
  value: any;
  textType?: TextType;
  language?: any;
  highlight?: any;  
}

export const ColumnRender: FC<ColumnRenderProps> = ({
  value,
  textType,
  language,
  highlight,
}) => {
  const isBigScreen = useMediaQuery(BigScreenQuery);

  const { lightMode } = useSettingsStore((state) => ({
    lightMode: state.lightMode,
  }));

  const getColumnRender = () => {
    if (!textType) {
      return String(value);
    }

    switch (textType) {
      case TextType.Artifact:
        return (
          <JsonViewer 
            rootName={false}
            editable={false}
            displayDataTypes={false}
            displaySize={false}
            enableClipboard={true}
            value={value}
            theme={lightMode ? "light" : "dark"}
            highlightUpdates={true}
            sx={{paddingLeft: 2}}
            defaultInspectDepth={0}
          />
        )
      case TextType.Url:
        return (
          <HLink
            ellipsis
            style={{ width: "200px" }}
            href={value}
            target="_blank"
            rel="noopener noreferrer"
            text={String(value)}
            tooltip={String(value)}
          />
        );
      case TextType.Map:
      case TextType.Array:
        if ("string" == typeof value) {
          value = value.split(",")
        }
        return value?.length > 0 && (
          <List
            dataSource={value}
            renderItem={(item :string) => (
              <List.Item >
                <Tooltip title={item}>
                  <Text style={{ width: isBigScreen ? "400px" : "200px" }} ellipsis>{item}</Text>
                </Tooltip>
              </List.Item>
            )}
          />
        );
      case TextType.Code: {
        let newValue = [...value].join("\n");
        const tabSpaces = "  ";
        const startNewLineRegEx = /^(\/n)/g;
        const endNewLineRegEx = /$\/n/g;
        newValue = newValue.replaceAll("\t", tabSpaces);
        newValue = newValue.replaceAll(startNewLineRegEx, "\n");
        newValue = newValue.replaceAll(endNewLineRegEx, "\n");
        return (
          <div style={{width: "auto"}}>
          <CodeBlock
            text={newValue}
            theme={lightMode ? atomOneLight :atomOneDark}
            language={language}
            showLineNumbers={false}
            wrapLongLines={false}
            as={undefined}
            forwardedAs={undefined}
            highlight={highlight}
          />
          </div>
        );
      }
      default:
        return (
          <Popover title={String(value)}>
            <Text ellipsis style={{ width: isBigScreen ? "400px" : "200px" }}>
              {String(value)}
            </Text>
          </Popover>
        );
    }
  };
  return (
    <div>{getColumnRender()}</div>
  )
}