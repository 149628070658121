import { axiosInterceptor } from "./axios";
import { Activity } from "types";
import { jsonApiDeserializer} from "./constant"
import { useAppConfigStore } from "store";


export const getActivitiesApi = async (
  pageNumber: number,
  pageSize: number,
  filters?: string[],
  sorter?: string[],
) : Promise<[Activity[], number]> => {
  
  let url = `${useAppConfigStore.getState().activitiesApiPath}?`

  if (pageNumber)
    url += `&page[number]=${pageNumber}`
  if (pageSize)
    url += `&page[size]=${pageSize}`

  if (sorter && sorter.length > 0) {
    sorter.map((sort) => {
      url += `&sort=${sort}`
    });
  }

  if (filters && filters.length > 0) {
    filters.map((filter) => {
      url += `&filter=${filter}`
    });
  }

  const response = await axiosInterceptor("get",url);
  const activities = jsonApiDeserializer.deserialize(response.data)  as Activity[];
  return [activities, response?.data?.meta?.totalCount];
};
