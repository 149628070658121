import { AxiosResponse } from "axios";
import { Tag, TagCreateRequest, TagList, TagUpdateRequest } from "types";
import { axiosInterceptor } from "./axios";
import { useAppConfigStore } from "store";

const getTagsApiPath = () => {
  const appState = useAppConfigStore.getState();
  return `${appState.tagsApiPath}`;
};


export const listTagsApi = async (): Promise<AxiosResponse<TagList>> => axiosInterceptor("get", getTagsApiPath());
export const getTagApi = async (id: string): Promise<AxiosResponse<Tag>> => axiosInterceptor("get", `${getTagsApiPath()}/${id}`);
export const createTagApi = async (tag: TagCreateRequest): Promise<AxiosResponse<Tag>> => axiosInterceptor("post", getTagsApiPath(), { ...tag });
export const updateTagApi = async (id: string, tag: TagUpdateRequest): Promise<AxiosResponse<Tag>> => axiosInterceptor("patch", `${getTagsApiPath()}/${id}`, tag);
export const deleteTagApi = async (id: string): Promise<AxiosResponse<void>> => axiosInterceptor("delete", `${getTagsApiPath()}/${id}`);

