export interface AppConfigDto {
  baseUrl: string;
  auth0Domain : string;
  auth0ClientId : string;
  auth0Audience : string;
  auth0Connection : string;
  auth0RedirectUri : string;
  keycloakDomain : string;
  keycloakClientId : string;
  keycloakRealm : string;
  signinUri : string;
  apiBaseUrl : string;
  signupApiBaseUrl : string;
  ssoLoginUrl : string;
  oauth2RedirectUri : string;
  environment : string;
  deployment : string;
}


export const getAppConfigApi = async (): Promise<AppConfigDto> => {
  const response = await fetch("/appconfig.json");
  return await response.json();
}
