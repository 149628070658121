
import { axiosInterceptor } from "./axios";
import { AxiosResponse } from "axios";
import {
  jsonApiDeserializer,
  camelCaseEnforcerJsonApiDeserializer,
} from "./constant"

import {
  App, 
  AppSubscription,
  AppEdgeRecommendActionResponse,
  SpecActionType,
  EdgeInfraType,
} from "types";
import { dtoFactory } from "utility/resource";
import { useAppConfigStore } from "store";


/** Add return type */
export const getAppsApi = async (
  pageNumber?: number,
  pageSize?: number,
  includeVersion?: boolean,
  subscribedOnly?: boolean,
  filters?: string[],
  sorter?: string[],
  fields?: string[],
) :  Promise<[App[], number]> => {  
  const appState = useAppConfigStore.getState();
  const appsApiPath = appState.appsApiPath;
  

  let url = `${appsApiPath}?includeAppSubscriptions=true`
  if (pageNumber && pageNumber != -1) 
    url += `&page[number]=${pageNumber}`
  if (pageSize && pageSize != -1) 
    url += `&page[size]=${pageSize}`
  if (includeVersion)
    url += `&includeVersions=true`
  if (subscribedOnly != undefined) 
    url += `&subscribedAppsOnly=${subscribedOnly}`

  if (fields && fields.length > 0) {
    url += `&fields[apps]=`+fields.join(',');
  }

  if (sorter && sorter.length > 0) {
    sorter.map((sort) => {
      url += `&sort=${sort}`
    });
  }

  if (filters && filters.length > 0) {
    filters.map((filter) => {
      url += `&filter=${filter}`
    });
  }
  
  const response = await axiosInterceptor("get", url);
  const apps = jsonApiDeserializer.deserialize(response.data) as App[];
  return [apps, response?.data?.meta?.totalCount];
};

export const getAppImagePathApi = (appId: string, lightMode: boolean) => {
  const appState = useAppConfigStore.getState();
  const appImageBasePath = appState.appImageBasePath;
  

  if(lightMode) {
    return appImageBasePath + `/${appId}/${appId}.svg`
  }
  return appImageBasePath + `/${appId}/${appId}_dark.svg`
}

export const getAppVersionsApi = async (appId: string):Promise<App[]> => {
  if(appId) {
    const response = await axiosInterceptor(
      "get",
      `${appId}}?includeVersions=true&filter=equals(id,'${appId}')&fields[apps]=version,versionName,comments&sort=-version`
    );
    
    return jsonApiDeserializer.deserialize(response.data)  as App[];
  }
  throw new Error("appId should be present in the app versions get");
};

export const getAppApi = async (
  appId: string
  ) : Promise<App> => {

    const appState = useAppConfigStore.getState();
    const appsApiPath = appState.appsApiPath;
    const response = await axiosInterceptor("get", (`${appsApiPath}/${appId}?includeAppSubscriptions=true`));
  return jsonApiDeserializer.deserialize(response.data) as App;
};

export const getRegionsApi = async (): Promise<Map<EdgeInfraType, []>> => {
  const appState = useAppConfigStore.getState();
  const appRegionsApiPath = appState.appRegionsApiPath;
  const response = await axiosInterceptor(
    "get",
    `${appRegionsApiPath}`
  );
  const data = jsonApiDeserializer.deserialize(response.data);
  const regionsMap = new Map<EdgeInfraType, []>;
  for (const [k, v] of Object.entries(data["Regions"])) {
    regionsMap.set(k as EdgeInfraType, v as []);
  }
  return regionsMap;
}

export const getRecommendedEdgesForAppApi = async (
  appId?: string,
  httpConfig?: any
  ) : Promise<AppEdgeRecommendActionResponse> => {
  const appState = useAppConfigStore.getState();
  const appEdgeApiPath = appState.appEdgeApiPath;
  const response = await axiosInterceptor("post",`${appEdgeApiPath}`,{
    "data": {
      "type": "app-edge",
      "attributes": {
          "appID": appId,
          "httpConfiguration": httpConfig
      }
    }
  });
  return camelCaseEnforcerJsonApiDeserializer.deserialize(response.data) as AppEdgeRecommendActionResponse;
};

export const createAppSubscriptionApi = async (appSubscription: AppSubscription) :  Promise<AppSubscription>=> {
  const appState = useAppConfigStore.getState();
  const appSubscriptionsApiPath = appState.appSubscriptionsApiPath;
  const response = await axiosInterceptor(
    "post",
    `${appSubscriptionsApiPath}`,
    {
      "data": {
        "type": "app-subscriptions",
        "attributes": dtoFactory.convertAppSubscriptionToUploadObject(appSubscription),
      }
    }
  );

  return jsonApiDeserializer.deserialize(response.data) as AppSubscription;
};

export const getAppSubscriptionsApi = async (
  pageNumber?: number,
  pageSize?: number,
  filters?: string[]
  ) : Promise<[AppSubscription[], number]> => { 

  const appState = useAppConfigStore.getState();
  const appSubscriptionsApiPath = appState.appSubscriptionsApiPath;
  let url = `${appSubscriptionsApiPath}?include=apps`
  if (pageNumber && pageNumber != -1) 
    url += `&page[number]=${pageNumber}`
  if (pageSize && pageSize != -1) 
    url += `&page[size]=${pageSize}`

  if (filters && filters.length > 0) {
    filters.map((filter) => {
      url += `&filter=${filter}`
    });
  }

  const response = await axiosInterceptor("get", url);
  const appsubscriptions = jsonApiDeserializer.deserialize(response.data) as AppSubscription[];
  return [appsubscriptions, response?.data?.meta?.totalCount];
};

export const getAppSubscriptionApi = async (
  appSubscriptionID?: string
  ) : Promise<AppSubscription> => {

  const appState = useAppConfigStore.getState();
  const appSubscriptionsApiPath = appState.appSubscriptionsApiPath;
    const response = await axiosInterceptor("get", (`${appSubscriptionsApiPath}/${appSubscriptionID}?includeConfig=true`));
  return jsonApiDeserializer.deserialize(response.data) as AppSubscription;
};

export const deleteAppSubscriptionApi = async (
  appSubscriptionId?: string,
  ) : Promise<AxiosResponse<any,any>>=> {
  const appState = useAppConfigStore.getState();
  const appSubscriptionsApiPath = appState.appSubscriptionsApiPath;
  const response = await axiosInterceptor("delete",`${appSubscriptionsApiPath}/${appSubscriptionId}`);

  return response;
};

export const updateAppSubscriptionApi =  async (appSubscription: AppSubscription) :  Promise<AxiosResponse<any, any>> => {
  const appState = useAppConfigStore.getState();
  const appSubscriptionsApiPath = appState.appSubscriptionsApiPath;
    return await axiosInterceptor(
      "patch",
      `${appSubscriptionsApiPath}/${appSubscription.id}`,{
      "data": {
        "type": "app-subscriptions",
        "id": appSubscription.id,
        "attributes": dtoFactory.convertAppSubscriptionToUploadObject(appSubscription),
      }
    });
}

export const getAppLogoPath = (appID: string): string => {
  const appState = useAppConfigStore.getState();
  const hypredgeAppBasePath = appState.baseUrl;
  return `${hypredgeAppBasePath}/apps/media/assets/${appID}/logo.img`;
}

export const createAppApi = async (app: App) :  Promise<App>=> {
  const appState = useAppConfigStore.getState();
  const appsApiPath = appState.appsApiPath;
  const response = await axiosInterceptor(
    "post",
    `${appsApiPath}`,
    {
        "data": {
            "type": "apps",
            "attributes": dtoFactory.convertAppToUploadObject(app),
        }
    }
  );
  const appOut = jsonApiDeserializer.deserialize(response.data)  as App;
  return appOut;
};

export const updateAppApi = async (app: App): Promise<AxiosResponse<any, any>> => {
  const appState = useAppConfigStore.getState();
  const appsApiPath = appState.appsApiPath;
  return axiosInterceptor(
    "patch",
    `${appsApiPath}/${app.id}`,
    {
      "data": {
        "type": "apps",      
        "id": app.id,
        "attributes": dtoFactory.convertAppToUploadObject(app),
      }
    }
  );
};

export const deleteAppApi = async(appId: string):  Promise<AxiosResponse<any,any>>=> {
  const appState = useAppConfigStore.getState();
  const appsApiPath = appState.appsApiPath;
  return  await axiosInterceptor("delete",`${appsApiPath}/${appId}`);
}

export const actionAppApi = async (appId: string, action: SpecActionType, app?: unknown) => {
  const appState = useAppConfigStore.getState();
  const appsApiPath = appState.appsApiPath;
  const response = await axiosInterceptor(
    "post",
    `${appsApiPath}/${appId}/${action}`,
      app 
      && {
            "data": {
              "type": "apps",      
              "id": appId,
              "attributes": app,
            }
          }
    );
    return response && response.data ? jsonApiDeserializer.deserialize(response.data) : null;
};

