import {  SearchRunActionStatus, SearchRunAction, SearchActionInfos, AppInfos, AppInfo, RunSearchActionEdgeResult, SearchRunStatus, Action, SearchRun } from "types";
import { workflowIcons } from "assets/icons";
import { SvgIcon } from "components/SvgIcon";

import {
  Space,
  Card,
  Typography,
  theme,
  Skeleton,
  Alert,
  Button,
  Badge,
  GlobalToken,
} from "antd";
import { useWorkflowStepsStore } from "store";

const { Text, Link } = Typography;

export const getSearchRunStatusIcon = (status: SearchRunActionStatus) => {
  switch (status) {
    case SearchRunActionStatus.Running:
      return <SvgIcon size={18} component={workflowIcons.logProgressIcon} hexColor={"orange"} />
    case SearchRunActionStatus.Completed:
      return <SvgIcon size={18} component={workflowIcons.logSuccessIcon} hexColor="green" />
    case SearchRunActionStatus.Failed:
      return <SvgIcon size={18} component={workflowIcons.logFailedIcon} hexColor="red" />
    default:
      return <></>
  }
};

export const getSearchRunStatusBadge = (actionDisplayName: string, status: SearchRunActionStatus) => {
  switch (status) {
    case SearchRunActionStatus.Running:
      return <Badge count={actionDisplayName} color="orange"/>
    case SearchRunActionStatus.Completed:
      return <Badge count={actionDisplayName} color="green"/>
    case SearchRunActionStatus.Failed:
      return <Badge count={actionDisplayName} color="red"/>
    default:
      return <></>
  }
}

export const getSubHeadingMessageForSearchActionResults = (searchRunAction: SearchRunAction) => {
  return `Applied on ${searchRunAction.resultCount} records, ${searchRunAction.metrics?.timeTaken}, across
      ${searchRunAction.metrics?.appsCount} apps`
};

export const getSubHeadingMessageForActionBanner = (searchRunAction: SearchRunAction) => {
  let message = `Action applied on ${searchRunAction.resultCount} records`;
  if(searchRunAction.metrics?.timeTaken) {
    message += `, ${searchRunAction.metrics?.timeTaken}`
  }
  if(searchRunAction.metrics?.appsCount) {
    message += `, using ${searchRunAction.metrics?.appsCount} apps`;
  }
  return message;
};

export const getRunActionStatus = (sra: SearchRunAction) => {
  switch (sra?.status) {
    case SearchRunActionStatus.Running:
      return <SvgIcon size={16} component={workflowIcons.logProgressIcon} hexColor={"orange"} />
    case SearchRunActionStatus.Completed:
      return <SvgIcon size={16} component={workflowIcons.logSuccessIcon} hexColor="green" />
    case SearchRunActionStatus.Failed:
    case SearchRunActionStatus.Cancelled:
    case SearchRunActionStatus.Terminated:
    case SearchRunActionStatus.TimedOut:
      return <SvgIcon size={16} component={workflowIcons.logFailedIcon} hexColor="red" />
    default:
      return <></>;
  }
};


export const getRunActionEdgeStatus = (result: RunSearchActionEdgeResult) => {
  switch (result?.status) {
    case true:
      return <SvgIcon size={18} component={workflowIcons.logSuccessIcon} hexColor="green" />
    case false:
      return <SvgIcon size={18} component={workflowIcons.logFailedIcon} hexColor="red" />
    default:
      return <SvgIcon size={18} component={workflowIcons.logProgressIcon} hexColor={"orange"} />
  }
};

export const getRunActionEdgeStatusBadge = (actionDisplayName: string, result: RunSearchActionEdgeResult) => {
  switch (result?.status) {
    case true:
      return <Badge count={actionDisplayName} color="green"/>
    case false:
      return <Badge count={actionDisplayName} color="red"/>
    default:
      return  <Badge count={actionDisplayName} color="orange"/>
  }
}
export const getActionName = (actionId: string) => {
  const actions = useWorkflowStepsStore.getState().actions;
  const action = actions.find((i: Action) => i.id == actionId) as Action;
  return action ? action.displayName : "";
}

export const getAppsInfos = (sra: SearchRunAction, actionInfos: SearchActionInfos) => {
  const appInfos: AppInfos =  [];
  if(sra.resultCount && sra.resultMetas) {  
    for(const rm of sra.resultMetas){
      let foundAppSubscription = false;
      for(const actionInfo of actionInfos) {
        if(!actionInfo.appInfos) continue;
        for(const appInfo of actionInfo.appInfos){
          if(!appInfo.appSubscriptionsInfos) continue
          for(const appSubscriptionInfo of appInfo.appSubscriptionsInfos) {
            if(rm.appSubscriptionID == appSubscriptionInfo.id) {
              const formulatedAppInfo = appInfo;
              formulatedAppInfo.appSubscriptionsInfos = [appSubscriptionInfo];
              appInfos.push(formulatedAppInfo);
              foundAppSubscription = true;
              break;
            }
            if(foundAppSubscription) break;
          }
          if(foundAppSubscription) break;
        }
        if(foundAppSubscription) break;
      }
    }
  }
  return appInfos;
}

export const getAppInfoBySubscriptionId = (appSubscriptionId: string, actionInfos: SearchActionInfos) => {
  for(const actionInfo of actionInfos) {
    for(const appInfo of actionInfo.appInfos){
      if(appInfo.appSubscriptionsInfos) {
        for(const appSubscriptionInfo of appInfo.appSubscriptionsInfos) {
          if(appSubscriptionId == appSubscriptionInfo.id) {
            return appInfo;
          }
        }
      }
    }
  }
  return {} as AppInfo;
}


export const getAppsBadges = (sra: SearchRunAction, actionInfos: SearchActionInfos, token: GlobalToken) => {
  const appInfos: AppInfos =  getAppsInfos(sra, actionInfos);
  return (
    <>
      {appInfos.length ? (
        <Space size={token.size} style={{ display: "flex" }}>
          {appInfos.map( (appInfo) => (
            <Badge key={appInfo.id} count={`${appInfo.displayName}(${appInfo.appSubscriptionsInfos[0].name})`}/>
          ))}
        </Space>
      ): (
        null
      )}
    </>
  );
}

export const getSubHeadingForSearchActionResults = (sra: SearchRunAction, token: GlobalToken) => {
  if(sra.actionInfo && sra.resultMetas && sra.metrics) {  
    const actionName = sra.actionInfo?.displayName
    return (
      <Space>
        <Badge count={actionName} color={token.colorPrimary}></Badge>
        <Text>{getSubHeadingMessageForSearchActionResults(sra)}</Text>
      </Space>
    )
  }
  return (
    <></>
  )
};

export const getSubHeadingMessageForSearchResults = (sr: SearchRun): string => {
    if (
      sr.status == SearchRunStatus.Running ||
      sr.resultCount == undefined
    ) {
      return `searching...across apps`;
    } else {
      return (
        sr.resultCount +
        " total search results" +
        (sr.metrics?.timeTaken
          ? ", " + sr.metrics?.timeTaken
          : "") +
        (sr.metrics?.appsCount
          ? ", across " + sr.metrics?.appsCount + " apps"
          : "") +
        (sr.metrics?.tenantsCount
          ? ", and " + sr.metrics?.tenantsCount + " tenants"
          : "")
      );
    }
  };

export const getActionAlertType = (status: SearchRunActionStatus) =>{
  switch (status) {
    case SearchRunActionStatus.Running:
      return "info"
    case SearchRunActionStatus.Completed:
      return "success"
    case SearchRunActionStatus.Failed:
      return "error"
    default:
      return "warning"
  }
}

export const getSearchAlertType = (status: SearchRunStatus) =>{
  switch (status) {
    case SearchRunStatus.Running:
      return "info"
    case SearchRunStatus.Completed:
      return "success"
    case SearchRunStatus.Failed:
      return "error"
    default:
      return "warning"
  }
}

export const getSearchRunIdsListFromSearchRun = (searchRun: SearchRun) => {
  const searchRunIds: string[] = [];
  searchRun.resultMetas.forEach ( (rm) => {
    const alreadyPresent = searchRunIds.find ((id) => id == rm.searchRunID);
    if(!alreadyPresent) {
      searchRunIds.push(rm.searchRunID);
    }
  });
  return searchRunIds.join(",");
}