import { AxiosResponse } from "axios";
import { axiosInterceptor } from "./axios";
import { jsonApiDeserializer } from "./constant";
import { Policy } from "types";
import { useAppConfigStore } from "store";


const getVisibilityPoliciesApiPath = () => {
  const appState = useAppConfigStore.getState();
  return `${appState.visibilityPoliciesApiPath}`;
}

export const getVisibilityPoliciesApi = async (category:string, name?: string): Promise<Policy[]> => {
    let url = `${getVisibilityPoliciesApiPath()}?filter=equals(category,'${category}')`;
    
    if (name) 
      url += `&filter=equals(name,'${name}')`
  
    const response = await axiosInterceptor("get", url);
    return jsonApiDeserializer.deserialize(response.data) as Policy[];
  };

  export const updateVisibilityPolicyApi = async (policy: Policy): Promise<AxiosResponse<any, any>> => {
    return axiosInterceptor(
      "patch",
      `${getVisibilityPoliciesApiPath()}/${policy.id}`,
      {
        "data": {
          "type": "policies",      
          "id": policy.id,
          "attributes": policy,
        }
      }
    );
  };