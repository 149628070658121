import { Manifest } from "./manifest";

export const enum UpdateStatusCode {
    NotRunning = "not_running",
	Unspecified = "unspecified",
	Running = "running",
	Completed = "completed",
	Failed = "failed",
	Canceled = "canceled",
	Terminated = "terminated",
	TimedOut = "timed_out",
	Waiting = "waiting",
}

export const UpdateStatusCodeMap: Map<UpdateStatusCode, string>  =  new Map<UpdateStatusCode, string> (
    [
      [UpdateStatusCode.NotRunning, "Not Updating"], 
      [UpdateStatusCode.Unspecified, "Unspecified"],
      [UpdateStatusCode.Running, "In Progress"], 
      [UpdateStatusCode.Completed, "Completed"],
      [UpdateStatusCode.Failed, "Failed"], 
      [UpdateStatusCode.Canceled, "Canceled"], 
      [UpdateStatusCode.Terminated, "Terminated"],
      [UpdateStatusCode.TimedOut, "Timed Out"],
      [UpdateStatusCode.Waiting, "Waiting"],
    ]
  )

export interface Update {
    id: string;
    name: string;
    version: string;
    status: UpdateStatusCode;
    reason: string;
    workflowID: string;
    error: string;
    startTime: string;
    endtime: string;
    userID: string;
    tenantID: string;
    createdAt: string;
    updatedAt: string;
}