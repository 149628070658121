import { create } from "zustand"
import { devtools } from "zustand/middleware";

import { PageInfo } from "types";

import { getUpdateApi, getUpdatesApi, getUpdateSettingsApi, runUpdateNowApi } from "api";
import { FilterValue, SorterResult } from "antd/es/table/interface";
import { DataType } from "@textea/json-viewer";
import { convertToApiFilters, convertToApiSorters } from "./utils";
import { Update, UpdateSettings, UpdateStore } from "types/updater";

export const useUpdateStore = create<UpdateStore>()(
  devtools((set, get) => ({
    updates: [],
    totalCount: 0,
    pageInfo: {number:1, size: 12, total: 0} as PageInfo,
    filter: {} as Record<string, FilterValue | null>,
    sorter: {} as SorterResult<DataType>,
    searchTextMap: new Map<string, string>(),
    settings: {} as UpdateSettings,
    currentUpdate: {} as Update,

    getUpdates: async () => {
      try {
        const pageInfo = get().pageInfo;
        const filters = get().filter;
        const searches = get().searchTextMap;
        const sorters = get().sorter;
        
        const updateFilters = convertToApiFilters(filters, searches)     
        const updateSorters = convertToApiSorters(sorters);
        //default sorting
        if (updateSorters.length == 0) {
          updateSorters.push("-startTime");
        }

        const pageNumber = pageInfo.number;
        const pageSize = pageInfo.size
        const [updates, totalCount] = await getUpdatesApi(pageNumber, pageSize, updateFilters, updateSorters);
        
        set((state) => ({
          ...state,
          updates: [...updates],
          totalCount: totalCount,
        }));
      } catch (error: any) {
        console.error(error);
        throw new Error(error);
      }
    },

    clearUpdates: () => {
      set((state) => ({
        ...state,
        updates: [],
        totalCount: 0,
        currentUpdate: {} as Update,
        pageInfo: { number: 1, size: 12, total: 0 } as PageInfo,
      }));
    },

    getUpdateApi: async (updateId: string) => {
      try {
        const update = await getUpdateApi(updateId);

        set((state) => ({
          ...state,
          currentUpdate: {...update},
        }));
        return update;
      } catch (error: any) {
        console.error(error);
        throw new Error(error);
      }
    },

    runUpdateNow: async () => {
      try {
        const update = await runUpdateNowApi();

        set((state) => ({
          ...state,
          currentUpdate: {...update},
        }));
        return update;
      } catch (error: any) {
        console.error(error);
        throw new Error(error);
      }
    },

    getSettings: async () => {
      try {
        const settings = await getUpdateSettingsApi();

        set((state) => ({
          ...state,
          settings: {...settings},
        }));
      } catch (error: any) {
        console.error(error);
        throw new Error(error);
      }
    },

  
    setCurrentPage: (pageNumber?: number, pageSize?: number) => {
      set( (state) => {
        const newPage: PageInfo = {number: pageNumber?pageNumber:1, size: pageSize?pageSize:12, total: state.pageInfo.total};
        return { ...state, pageInfo : newPage};
      });
      return;
    },

    setFilter: (filters?: Record<string, FilterValue | null>) => {
      set( (state) => {
        return { ...state, filter : filters};
      });
      return;
    },

    setSorter: (sorters?: SorterResult<DataType> | SorterResult<DataType>[]) => {
      set( (state) => {
        return { ...state, sorter : sorters};
      });
      return;
    },

    setSearchTextMap: (searches: Map<string, string>) => {
      set((state) => ({
        ...state,
        searchTextMap: new Map<string, string>(searches),
      }));
    },
  }),
  { name: "AuditLogStore" })
);
