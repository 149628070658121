import { ProjectionRows, RunSearchActionEdgeResult } from "types";
import { axiosInterceptor, AxiosConfig} from "./axios";
import { AxiosHeaders } from "axios";
import { useAppConfigStore } from "store";

const getTenantHeaderKeyID = () => {
  const appState = useAppConfigStore.getState();
  return `${appState.tenantHeaderKeyID}`;  
}


export const getDataByPathApi = async (
  edgeUrl: string,
  tenantId: string,
  path: string,
  fields?: string) : Promise<any> => {
  
  const config: AxiosConfig = {};
  config.headers = {} as AxiosHeaders;
  config.headers[getTenantHeaderKeyID()] = tenantId;

  let url = `${edgeUrl}/edge-data?path=${path}`
  
  if (fields) {
    url += `&fields=${fields}`
  }

  const response = await axiosInterceptor("get",url,null,config);

  return response.data;
};

export const getWorkflowParametersMetaApi  = async (
  edgeUrl: string,
  tenantId: string,
  workflowId: string,
  workflowRunId: string) : Promise<any> => {
  
  const config: AxiosConfig = {};
  config.headers = {} as AxiosHeaders;
  config.headers[getTenantHeaderKeyID()] = tenantId;
  
  const url = `${edgeUrl}/edge-data?path=workflow-runs/${workflowId}/${workflowRunId}/parameters/meta`

  const response = await axiosInterceptor("get",url,null,config);

  return response.data;
};


export const getWorkflowParametersApi = async (
  edgeUrl: string,
  tenantId: string,
  workflowId: string,
  workflowRunId: string) : Promise<any[]> => {
  
  const config: AxiosConfig = {};
  config.headers = {} as AxiosHeaders;
  config.headers[getTenantHeaderKeyID()] = tenantId;

  const url = `${edgeUrl}/edge-data?path=workflow-runs/${workflowId}/${workflowRunId}/parameters`

  const response = await axiosInterceptor("get",url,null,config);

  return response.data;
};

export const getWorkflowOutputsMetaApi  = async (
  edgeUrl: string,
  tenantId: string,
  workflowId: string,
  workflowRunId: string) : Promise<any> => {
  
  const config: AxiosConfig = {};
  config.headers = {} as AxiosHeaders;
  config.headers[getTenantHeaderKeyID()] = tenantId;

  const url = `${edgeUrl}/edge-data?path=workflow-runs/${workflowId}/${workflowRunId}/outputs/meta`

  const response = await axiosInterceptor("get",url,null,config);

  return response.data;
};

export const getWorkflowOutputsApi = async (
  edgeUrl: string,
  tenantId: string,
  workflowId: string,
  workflowRunId: string) : Promise<any[]> => {
  
  const config: AxiosConfig = {};
  config.headers = {} as AxiosHeaders;
  config.headers[getTenantHeaderKeyID()] = tenantId;
 
  const url = `${edgeUrl}/edge-data?path=workflow-runs/${workflowId}/${workflowRunId}/outputs`

  const response = await axiosInterceptor("get",url,null,config);

  return response.data;
};

export const getTriggerParametersApi = async (
  edgeUrl: string,
  tenantId: string,
  workflowId: string,
  workflowRunId: string) : Promise<any[]> => {
  
  const config: AxiosConfig = {};
  config.headers = {} as AxiosHeaders;
  config.headers[getTenantHeaderKeyID()] = tenantId;
  
  const url = `${edgeUrl}/edge-data?path=workflow-runs/${workflowId}/${workflowRunId}/trigger/parameters`

  const response = await axiosInterceptor("get",url,null,config);

  return response.data;
};

export const getTriggerOutputsApi = async (
  edgeUrl: string,
  tenantId: string,
  workflowId: string,
  workflowRunId: string) : Promise<any[]> => {
  
  const config: AxiosConfig = {};
  config.headers = {} as AxiosHeaders;
  config.headers[getTenantHeaderKeyID()] = tenantId;

  const url = `${edgeUrl}/edge-data?path=workflow-runs/${workflowId}/${workflowRunId}/trigger/outputs`

  const response = await axiosInterceptor("get",url,null,config);

  return response.data;
};

export const getTriggerLogsApi = async (
  edgeUrl: string,
  tenantId: string,
  workflowId: string,
  workflowRunId: string,
  showTimeStamp: boolean,
  showDetailLogs: boolean) : Promise<any[]> => {
  
  const config: AxiosConfig = {};
  config.headers = {} as AxiosHeaders;
  config.headers[getTenantHeaderKeyID()] = tenantId;

  const url = `${edgeUrl}/logs?path=workflow-runs/${workflowId}/${workflowRunId}/trigger/logs&includeTimeStamp=${showTimeStamp}&includeDebugLogs=${showDetailLogs}`

  const response = await axiosInterceptor("get",url,null,config);

  return response.data;
};

export const getOperatorParametersApi = async (
  edgeUrl: string,
  tenantId: string,
  workflowId: string,
  workflowRunId: string,
  stepId: string) : Promise<any[]> => {

  const config: AxiosConfig = {};
  config.headers = {} as AxiosHeaders;
  config.headers[getTenantHeaderKeyID()] = tenantId;

  const url = `${edgeUrl}/edge-data?path=workflow-runs/${workflowId}/${workflowRunId}/operator/${stepId}/parameters`

  const response = await axiosInterceptor("get",url,null,config);

  return response.data;
};

export const getOperatorParametersMetaApi = async (
  edgeUrl: string,
  tenantId: string,
  workflowId: string,
  workflowRunId: string,
  stepId: string) : Promise<any> => {
  
  const config: AxiosConfig = {};
  config.headers = {} as AxiosHeaders;
  config.headers[getTenantHeaderKeyID()] = tenantId;

  const url = `${edgeUrl}/edge-data?path=workflow-runs/${workflowId}/${workflowRunId}/operator/${stepId}/parameters/meta`

  const response = await axiosInterceptor("get",url,null,config);

  return response.data;
};

export const getOperatorOutputsMetaApi = async (
  edgeUrl: string,
  tenantId: string,
  workflowId: string,
  workflowRunId: string,
  stepId: string) : Promise<any> => {
  
  const config: AxiosConfig = {};
  config.headers = {} as AxiosHeaders;
  config.headers[getTenantHeaderKeyID()] = tenantId;

  const url = `${edgeUrl}/edge-data?path=workflow-runs/${workflowId}/${workflowRunId}/operator/${stepId}/outputs/meta`

  const response = await axiosInterceptor("get",url,null,config);

  return response.data;
};

export const getOperatorOutputsApi = async (
  edgeUrl: string,
  tenantId: string,
  workflowId: string,
  workflowRunId: string,
  stepId: string) : Promise<any[]> => {
  
  const config: AxiosConfig = {};
  config.headers = {} as AxiosHeaders;
  config.headers[getTenantHeaderKeyID()] = tenantId;

  const url = `${edgeUrl}/edge-data?path=workflow-runs/${workflowId}/${workflowRunId}/operator/${stepId}/outputs`

  const response = await axiosInterceptor("get",url,null,config);

  return response.data;
};

export const getOperatorLogsApi = async (
  edgeUrl: string,
  tenantId: string,
  workflowId: string,
  workflowRunId: string,
  stepId: string,
  showTimeStamp: boolean,
  showDetailLogs: boolean) : Promise<any[]> => {
  
  const config: AxiosConfig = {};
  config.headers = {} as AxiosHeaders;
  config.headers[getTenantHeaderKeyID()] = tenantId;

  const url = `${edgeUrl}/logs?path=workflow-runs/${workflowId}/${workflowRunId}/operator/${stepId}/logs&includeTimeStamp=${showTimeStamp}&includeDebugLogs=${showDetailLogs}`

  const response = await axiosInterceptor("get",url,null,config);

  return response.data;
};

export const getActionParametersApi = async (
  edgeUrl: string,
  tenantId: string,
  workflowId: string,
  workflowRunId: string,
  stepId: string,
  providerId: string) : Promise<any[]> => {
  
  const config: AxiosConfig = {};
  config.headers = {} as AxiosHeaders;
  config.headers[getTenantHeaderKeyID()] = tenantId;

  const url = `${edgeUrl}/edge-data?path=workflow-runs/${workflowId}/${workflowRunId}/action/${stepId}/${providerId}/parameters`

  const response = await axiosInterceptor("get",url,null,config);

  return response.data;
};

export const getActionOutputsApi = async (
  edgeUrl: string,
  tenantId: string,
  workflowId: string,
  workflowRunId: string,
  stepId: string,
  providerId: string) : Promise<any[]> => {
  
  const config: AxiosConfig = {};
  config.headers = {} as AxiosHeaders;
  config.headers[getTenantHeaderKeyID()] = tenantId;

  const url = `${edgeUrl}/edge-data?path=workflow-runs/${workflowId}/${workflowRunId}/action/${stepId}/${providerId}/outputs`

  const response = await axiosInterceptor("get",url,null,config);

  return response.data;
};

export const getActionLogsApi = async (
  edgeUrl: string,
  tenantId: string,
  workflowId: string,
  workflowRunId: string,
  stepId: string,
  providerId: string,
  showTimeStamp: boolean,
  showDetailLogs: boolean) : Promise<any[]> => {
  
  const config: AxiosConfig = {};
  config.headers = {} as AxiosHeaders;
  config.headers[getTenantHeaderKeyID()] = tenantId;

  const url = `${edgeUrl}/logs?path=workflow-runs/${workflowId}/${workflowRunId}/action/${stepId}/${providerId}/logs&includeTimeStamp=${showTimeStamp}&includeDebugLogs=${showDetailLogs}`

  const response = await axiosInterceptor("get",url,null,config);

  return response.data;
};

export const getSearchResultsApi = async (
  edgeUrl: string,
  tenantId: string,
  searchId: string,
  searchRunId: string,
  appSubscriptionId: string,
  artifactName: string,
  pageNumber: number,
  pageSize: number,
  detailedView: boolean) : Promise<ProjectionRows> => {

  const config: AxiosConfig = {};
  config.headers = {} as AxiosHeaders;
  config.headers[getTenantHeaderKeyID()] = tenantId;
    
  const url = `${edgeUrl}/search?path=search-runs/${searchId}/${searchRunId}/${appSubscriptionId}/results/${artifactName}&page[number]=${pageNumber}&page[size]=${pageSize}${detailedView ? "&fields[results]=*" : ""}`
  const response = await axiosInterceptor("get",url, null, config);
  return response.data as ProjectionRows;
}


export const getSearchActionOutputsApi = async (
  edgeUrl: string,
  tenantId: string,
  searchRunId: string,
  searchRunActionId: string,
  appSubscriptionId: string,
  rowId: string) : Promise<any> => {

  const config: AxiosConfig = {};
  config.headers = {} as AxiosHeaders;
  config.headers[getTenantHeaderKeyID()] = tenantId;

  const url = `${edgeUrl}/search?path=search-run-actions/${searchRunId}/${searchRunActionId}/${appSubscriptionId}/${rowId}/outputs`
  const response = await axiosInterceptor("get",url, null, config);
  return response.data
}

export const getSearchActionParametersApi = async (
  edgeUrl: string,
  tenantId: string,
  searchRunId: string,
  searchRunActionId: string,
  appSubscriptionId: string,
  rowId: string) : Promise<any> => {
  
  const config: AxiosConfig = {};
  config.headers = {} as AxiosHeaders;
  config.headers[getTenantHeaderKeyID()] = tenantId;
    
   
  const url = `${edgeUrl}/search?path=search-run-actions/${searchRunId}/${searchRunActionId}/${appSubscriptionId}/${rowId}/parameters`
  const response = await axiosInterceptor("get",url, null, config);
  return response.data
}

export const getSearchActionResultApi = async (
  edgeUrl: string,
  tenantId: string,
  searchRunId: string,
  searchRunActionId: string,
  appSubscriptionId: string,
  rowId: string) : Promise<RunSearchActionEdgeResult> => {
  
  const config: AxiosConfig = {};
  config.headers = {} as AxiosHeaders;
  config.headers[getTenantHeaderKeyID()] = tenantId;
   
  const url = `${edgeUrl}/search?path=search-run-actions/${searchRunId}/${searchRunActionId}/${appSubscriptionId}/${rowId}/result`
  const response = await axiosInterceptor("get",url, null, config);
  return response.data as RunSearchActionEdgeResult;
}

export const runAppActionApi = async (
  edgeUrl: string,
  tenantId: string,
  appSubscriptionId: string,
  adapterType: string,
  payload: any) : Promise<any> => {
  
  const config: AxiosConfig = {};
  config.headers = {} as AxiosHeaders;
  config.headers[getTenantHeaderKeyID()] = tenantId;
   
  const url = `${edgeUrl}/app-action/${appSubscriptionId}/${adapterType}`
  const response = await axiosInterceptor("post", url, payload, config);
  return response.data;
}