import { FC, useState } from "react";
import {
  theme,
  Tooltip,
  Card,
  Button
} from "antd";

import { SvgIcon } from "components/SvgIcon";
import { commonIcons } from "assets/icons";
import styles from "./SearchBar.module.scss";
import { useOrganizationStore } from "store";
import { AISearchQueryEngine, NativeSearchQueryEngine } from "types";


export interface SearchFilterButtonProps {
  isAiBasedQuery: boolean;
  onClick: () => void;
  onEngineChanged: (engine: string) => void;
}
export const SearchFilterButton: FC<SearchFilterButtonProps> = ({
  isAiBasedQuery,
  onClick,
  onEngineChanged,
}) => {
  const { token } = theme.useToken();
  const [hover, setHover] = useState(false);
  const { mspEnabled } = useOrganizationStore((state) => ({
    mspEnabled: state.mspEnabled,
  }));

  return (
    <Tooltip
      title={"Show search options"}
      placement={"bottom"}
    >
      <Button
         style={{
          cursor: "default",
          height: "2px",
          width: "2px",
          display: "flex",
          marginRight: "10px",
          marginLeft: "2px",
          backgroundColor: hover ? token.colorPrimaryBgHover : token.colorBgMask,
        }}
        icon={
          <SvgIcon
            className={isAiBasedQuery ? styles.aiEnabled : styles.aiDisabled}
            size={14}
            component={commonIcons.filterSortIcon}
            hexColor={token.colorBgMask}
          />
        }
        onClick={() => {
          if (mspEnabled) onClick();
          else {
            onEngineChanged(isAiBasedQuery ? NativeSearchQueryEngine : AISearchQueryEngine)
          }
        }}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      />

      {/* <Card.Grid
        style={{
          cursor: "default",
          height: "3px",
          width: "3px",
          display: "flex",
          marginRight: "15px",
          backgroundColor: hover ? token.colorPrimaryBgHover : token.colorBgMask,
        }}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onClick={() => {
          if (mspEnabled) onClick();
          else {
            onEngineChanged(isAiBasedQuery ? NativeSearchQueryEngine : AISearchQueryEngine)
          }
        }}
      >
        <SvgIcon
          className={isAiBasedQuery ? styles.aiEnabled : styles.aiDisabled}
          size={16}
          component={commonIcons.filterSortIcon}
          hexColor={hover ? token.colorPrimary : token.colorBgMask}
        />
      </Card.Grid> */}
    </Tooltip>
  )
};