import { PropsWithChildren, useEffect, useState } from "react";
import { useAppConfigStore, useOrganizationStore } from "store";
import { useTokenStore } from "store/token";
import { TokenUtils } from "types/utils";
import { setupContext } from "utility";
import { AuthenticationContext } from "./AuthContext";

export const TokenManager = (props: PropsWithChildren) => {
  const isProduction = useAppConfigStore((state) => state.isProduction);
  const selfHosted = useAppConfigStore((state) => state.isSelfHosted);
  const issuer = useAppConfigStore((state) => state.issuer);
  const auth = useAppConfigStore((state) => state.auth);

  /** Track any context request during context switch */
  const context = useOrganizationStore((state) => state.context);
  const contextRequest = useOrganizationStore((state) => state.contextRequest);
  /** The current context */
  const activateContext = useOrganizationStore(
    (state) => state.activateContext
  );
  const clearContextRequest = useOrganizationStore(
    (state) => state.clearContextRequest
  );
  const setToken = useTokenStore((state) => state.setToken);
  const token = useTokenStore((state) => state.token);
  const [tokenStatus, setTokenStatus] = useState(
    TokenUtils.getTokenStatus(token, `${issuer}`)
  );

  useEffect(() => {
    const status = TokenUtils.getTokenStatus(token, `${issuer}`);
    setTokenStatus(status);
    if (timer) {
      console.log("Clearing interval");
      clearInterval(timer);
      setTimer(null);
    }

    if (status.isAuthenticated) {
      const pollIntervalMs = 1000 * 120 * 1;
      console.log(`Setting up interval for ${pollIntervalMs} ms`);
      setTimer(setInterval(refreshToken, pollIntervalMs));
    }
  }, [token, issuer]);

  useEffect(() => {
    if (!contextRequest) return;
    if (!auth) return;
    setupContext({
      context: contextRequest,
      environment: isProduction ? "prod" : "dev",
      setToken,
      activateContext,
      clearContextRequest,
      silent: true,
      selfHosted: selfHosted,
      auth: auth,
    });
  }, [contextRequest, isProduction, auth, selfHosted]);

  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);

  const refreshToken = async () => {
    console.log("Refreshing token");
    if (!tokenStatus.isAuthenticated) {
      console.log("Not authenticated");
      return Promise.resolve();
    }

    if (!context) {
      console.log("No context");
      return Promise.resolve();
    }

    if (
      tokenStatus.expiry &&
      tokenStatus.expiry * 1000 - Date.now() > 15 * 60 * 1000
    )
      return Promise.resolve();

    const promise = new Promise<void>((resolve, reject) => {
      /** Do not force a check session if we have more than 15 minutes for expiry  */
      if (!auth) {
        console.log("No auth");
        reject("No auth");
      } else {
        if (!tokenStatus.isSso) {
          setupContext({
            context: context,
            environment: isProduction ? "prod" : "dev",
            setToken,
            activateContext,
            clearContextRequest,
            silent: true,
            auth: auth,
            selfHosted: selfHosted,
          }).catch((e) => {
            console.log(`Error refreshing token: ${e}`);
            reject(e);
          });
          resolve();
        } else {
          if (tokenStatus.expiry && tokenStatus.expiry * 1000 < Date.now()) {
            console.log(`Expiry has passed, Invalidating login`);
            setToken(null);
          }
          resolve();
        }
      }
    });

    return promise;
  };

  return (
    <AuthenticationContext.Provider
      value={{ authenticated: tokenStatus.isAuthenticated }}
    >
      {props.children}
    </AuthenticationContext.Provider>
  );
};
