import { Item } from "types/misc";

export enum MetricInterval {
  ALL = 'ALL',
  HOUR = 'HOUR',
  SIXHOURS = 'SIXHOURS',
  TWELVEHOURS = 'TWELVEHOURS',
  DAY = 'DAY',
  WEEK = 'WEEK',
  MONTH = 'MONTH',
  QUARTER = 'QUARTER',
  HALF = 'HALF',
  YEAR = 'YEAR',
}

export enum WidgetType {
  MiniTile = "MiniTile",
  Pie = "Pie",
  Distribution = "Distribution",
  Trend = "Trend"
}

export enum MetricLayoutIdentifier {
  MetricLayoutDashboardMeters       = "dashboard-meter",
  MetricLayoutDashboardProductivity = "dashboard-productivity",
  MetricLayoutDashboardApp = "dashboard-app",
  MetricLayoutDashboardWorkflow = "dashboard-workflow",
  MetricLayoutDashboardEdge = "dashboard-edge",
  MetricLayoutDashboardSearch = "dashboard-search",
  MetricLayoutDashboardActivity = "dashboard-activity",
  MetricLayoutDashboardCases = "dashboard-cases",
  MetricLayoutWorkspace = "workspace",
  MetricLayoutHyprflows = "hyprflows",
  MetricLayoutSearch = "search",
  MetricLayoutEdges = "edges",
  MetricLayoutApps = "apps",
  MetricLayoutUsers = "users",
  MetricLayoutCases = "cases",
}

export enum MetricIcons {
  MetricIconApps = "metricApps",
  MetricIconEdges = "metricEdges",
  MetricIconWorkflows = "metricWorkflows",
  MetricIconActions = "metricActions",
  MetricIconSearches = "metricSearch",
  MetricIconSearchExecutions = "metricSearch",
  MetricIconSearchActions = "metricActions",
  MetricIconIOSaved = "metricIOSaved",
  MetricIconTimeSaved = "metricTimeSaved",
  MetricIconUsers = "metricUsers",
  MetricIconRoles = "metricRoles",
  MetricIconPermissionSets = "metricPermissionSets",
  MetricIconRequests = "metricRequests",
  MetricIconDenies = "metricDenies",
  MetricIconResources = "metricResources",
  MetricIconCases = "metricCases",
}

export interface RawPoint {
  x: string;
  y: number;
}

export interface RawMetric {
  text: string;
  value: number;
  unit: string;
}

type CustomItem = Item;
export interface CustomMetric {
  items: CustomItem[];
}

export interface RawPointMetric {
  text: string;
  values: RawPoint[];
  unit: string;
}

export interface RawVectorMetric {
  text: string;
  values: number[];
  unit: string;
}


export interface MiniTileData {
  primary: RawMetric;
  secondary?: RawMetric;
  tertiary?: CustomMetric;
  secondaryNature?: 'error' | 'success' | 'info' | 'meter';
  icon?: string;
  interval: MetricInterval;
}


export interface DistributionData {
  primaryAxis: string;
  secondaryAxis: string;
  primary: RawPointMetric;
  interval: MetricInterval;
  icons?: string;
}

export interface PieData {
  primary: RawPointMetric;
  total: RawMetric;
  interval: MetricInterval;
  icon?: string;
}



export interface TrendData {
  primary: RawVectorMetric;
  average: RawMetric;
  total: RawMetric;
  icon?: string;
  interval: MetricInterval;
}

export interface Metric {
  id: string;
  widgetType: WidgetType,
  data: MiniTileData | DistributionData | PieData | TrendData;
}


export type LayoutMetrics = string[];

export interface MetricLayout {
  id: string;
  name: string;
  displayName: string;
  description: string;
  metrics: LayoutMetrics;
}

export const metricLayouts = new Map<string, MetricLayout>([
  ["apps", {
    displayName: "Apps",
    description: "Apps metric layout",
    metrics: [
      "apps-total",
      "active-edges-in-apps-total",
      "active-apps-in-searches-total",
      "active-apps-in-workflows-total",
      "time-saved-by-apps-total",
      "io-saved-by-apps-total"
    ]
  } as MetricLayout
  ],
  ["edges", {
    displayName: "Edges",
    description: "Edges metric layout",
    metrics: [
      "edges-total",
      "active-apps-in-edges-total",
      "active-edges-in-searches-total",
      "active-edges-in-workflows-total",
      "io-saved-by-edges-total",
      "time-saved-by-edges-total"
    ]
  } as MetricLayout
  ],
  ["search", {
    displayName: "Search",
    description: "Search metric layout",
    metrics: [
      "searches-total",
      "search-executions-total",
      "search-action-executions-total",
      "active-apps-in-searches-total",
      "time-saved-by-searches-total",
      "io-saved-by-searches-total"
    ]
  } as MetricLayout
  ],
  ["hyprflows", {
    displayName: "Hyprflows",
    description: "Hyprflows metric layout",
    metrics: [
      "workflows-total",
      "workflow-executions-total",
      "active-apps-in-workflows-total",
      "active-edges-in-workflows-total",
      "time-saved-by-workflows-total",
      "io-saved-by-workflows-total"
    ]
  } as MetricLayout
  ],
  ["users", {
    displayName: "Users",
    description: "Users metric layout",
    metrics: [
      "users-total",
      "roles-total",
      "permission-sets-total",
      "requests-total",
      "resources-total",
      "denied-total"
    ]
  } as MetricLayout
  ],
  ["workspace", {
    displayName: "Workspace",
    description: "Workspace metric layout",
    metrics: [
      "time-saved-by-workflows-total",
      "io-saved-by-workflows-total",
      "time-saved-by-searches-total",
      "io-saved-by-searches-total",
      "time-saved-by-search-actions-total",
      "io-saved-by-search-actions-total"
    ]
  } as MetricLayout
  ],
  ["cases", {
    displayName: "Cases",
    description: "Cases metric layout",
    metrics: [
      "cases-open-active-total",
      "cases-critical-active-total",
      "cases-priority-active-total",
      "cases-open-average-active-total",
      "cases-open-total",
      "cases-critical-total",
    ]
  } as MetricLayout
  ],
  ["dashboard-activity", {
    displayName: "Activity",
    description: "Dashboard activity metric layout",
    metrics: [
      "app-used-by-executions-total-pie",
      "edges-by-io-pie",
      "workflows-by-execution-total-pie",
      "artifacts-by-execution-total-pie",
      "actions-by-execution-total-pie"
    ]
  } as MetricLayout
  ],
  ["dashboard-app", {
    displayName: "Apps",
    description: "Dashboard apps metric layout",
    metrics: [
      "apps-total",
      "apps-subscribed-total",
      "active-edges-in-apps-total",
      "active-apps-in-workflows-total",
      "active-apps-in-search-executions-total",
      "active-apps-in-search-action-executions-total",
      "time-saved-by-apps-distribution",
      "io-saved-by-apps-pie"
    ]
  } as MetricLayout
  ],
  ["dashboard-edge", {
    displayName: "Edges",
    description: "Dashboard edges metric layout",
    metrics: [
      "edges-total",
      "edges-subscribed-total",
      "active-apps-in-edges-total",
      "active-edges-in-workflows-total",
      "active-edges-in-search-executions-total",
      "active-edges-in-search-actions-total",
      "time-saved-by-edges-distribution",
      "io-saved-by-edges-pie"
    ]
  } as MetricLayout
  ],
  ["dashboard-productivity", {
    displayName: "Productivity",
    description: "Dashboard productivity metric layout",
    metrics: [
      "io-saved-total",
      "time-saved-total",
      "workflow-executions-total",
      "search-executions-total",
      "users-total",
      "roles-total",
      "time-saved-by-workflows-distribution",
      "time-saved-by-searches-distribution"
    ]
  } as MetricLayout
  ],
  ["dashboard-search", {
    displayName: "Searches",
    description: "Dashboard searches metric layout",
    metrics: [
      "searches-total",
      "search-action-executions-total",
      "search-executions-total",
      "search-executions-adhoc-total",
      "active-apps-in-searches-total",
      "active-edges-in-searches-total",
      "time-saved-by-searches-distribution",
      "io-saved-by-searches-pie"
    ]
  } as MetricLayout
  ],
  ["dashboard-workflow", {
    displayName: "Workflows",
    description: "Dashboard Workflows metric layout",
    metrics: [
      "workflows-total",
      "workflows-configured-total",
      "workflows-published-total",
      "workflow-executions-total",
      "active-apps-in-workflows-total",
      "active-edges-in-workflows-total",
      "time-saved-by-workflows-distribution",
      "io-saved-by-workflows-pie"
    ],

  } as MetricLayout
  ],
  ["dashboard-cases", {
    displayName: "Cases",
    description: "Dashboard Cases metric layout",
    metrics: [
      "cases-open-active-total",
      "cases-critical-active-total",
      "cases-priority-active-total",
      "cases-open-average-active-total",
      "cases-open-total",
      "cases-critical-total",
      "cases-total-by-count-pie",
      "cases-total-by-users-pie"
    ],

  } as MetricLayout
  ],
  ["dashboard-meter", {
    displayName: "Meters",
    description: "Dashboard meters metric layout",
    metrics: [
      "counter-workflow-executions-total",
      "counter-search-executions-total",
      "counter-step-executions-total",
      "counter-artifact-executions-total",
      "counter-io-saved-total",
      "counter-time-saved-total",
    ],

  } as MetricLayout
  ],

]);
