import { AxiosResponse } from "axios";
import { axiosInterceptor } from "./axios";
import { useAppConfigStore } from "store";

export const initiateSignUpApi = async (
  business: string,
  email: string,
  firstName: string,
  lastName: string,
  phone: string,
  region: string,
  code: string,
  challenge: string,
  isManufacturer: boolean,
  supportEmail: string,
  supportPhone: string
  ) : Promise<AxiosResponse<any, any>> => {
    const appState = useAppConfigStore.getState();
    const tenantsApiPath = appState.tenantsApiPath;
  const response = await axiosInterceptor("post", `${tenantsApiPath}`, {
    business,
    email,
    firstName,
    lastName,
    phone,
    region,
    code,
    challenge,
    isManufacturer,
    supportEmail,
    supportPhone,
  });
  return response;
};

export const signUpValidateApi = async (
  verifier: string,
  otp: string,
  tenantId: string
  ) : Promise<AxiosResponse<any, any>> => {
    const appState = useAppConfigStore.getState();
    const tenantsApiPath = appState.tenantsApiPath;

  const response = await axiosInterceptor("post",`${tenantsApiPath}/${tenantId}`, {
    otp,
    verifier,
  });

  return response;
};

export const signUpSetPasswordApi = async (
  verifier: string,
  password: string,
  tenantId: string,
  ):Promise<AxiosResponse<any, any>> => {
    const appState = useAppConfigStore.getState();
    const tenantsApiPath = appState.tenantsApiPath;

    const response = await axiosInterceptor("patch",`${tenantsApiPath}/${tenantId}`, {
    password,
    verifier,
  });  
  return response;
};

export const signupResetPasswordApi = async ( email: string) : Promise<AxiosResponse<any, any>> => {
  const appState = useAppConfigStore.getState();
  const passwordResetApiPath = appState.passwordResetApiPath;
  const response = await axiosInterceptor("post", `${passwordResetApiPath}`, {
    email,
  });
  return response;
}

