import { Space, Tabs, theme, Typography } from "antd";
import { FC } from "react";
const { Text, Title } = Typography;

import { administrationIcons, commonIcons } from "assets/icons";
import { EnterpriseSso } from "components/EnterpriseSso";
import { SvgIcon } from "components/SvgIcon";
import AuditLogs from "../../components/AuditLogs";
import Content from "../../components/Content";
import PageWrapper from "../../components/PageWrapper";
import ParameterStore from "components/ParameterStore";
import { Policies } from "components/Policies";
import { useAppConfigStore, useOrganizationStore } from "store";
import { VideoPlayer } from "components/VideoPlayer";
import { QuickLinkComponent, QuickLinkMetaMap } from "types/quicklinks";

const Administration: FC = () => {
  const { token } = theme.useToken();
  const isSelfHosted = useAppConfigStore((state) => state.isSelfHosted);

  const context = useOrganizationStore((state) => state.context);

  const tabItems = [
    {
      label: (
        <Space size={token.marginXXS}>
          <SvgIcon
            size={15}
            component={administrationIcons.adminPoliciesIcon}
          />
          <Text>Policies</Text>
        </Space>
      ),
      key: "policies",
      forceRender: true,
      visible: context?.endsWith("_tag"),
      children: <Policies />,
    },
    {
      label: (
        <Space size={token.marginXXS}>
          <SvgIcon
            size={15}
            component={administrationIcons.adminParamterStoreIcon}
          />
          <Text>Parameter Store</Text>
        </Space>
      ),
      key: "parameterStore",
      forceRender: true,
      visible: context?.endsWith("_tag"),
      children: <ParameterStore />,
    },
    {
      label: (
        <Space size={token.marginXXS}>
          <SvgIcon
            size={15}
            component={administrationIcons.adminContentStoreIcon}
          />
          <Text>Content</Text>
        </Space>
      ),
      key: "content",
      forceRender: true,
      visible: isSelfHosted,
      children: <Content />,
    },
    {
      label: (
        <Space size={token.marginXXS}>
          <SvgIcon size={15} component={commonIcons.logsIcon} />
          <Text>Audit Logs</Text>
        </Space>
      ),
      key: "auditLogs",
      forceRender: true,
      visible: true,
      children: <AuditLogs />,
    },
    {
      label: (
        <Space size={token.marginXXS}>
          <SvgIcon size={15} component={administrationIcons.adminSSOIcon} />
          <Text>Single Sign On</Text>
        </Space>
      ),
      key: "sso",
      forceRender: true,
      visible: true,
      children: <EnterpriseSso />,
    },
  ];

  return (
    <PageWrapper
      content={
        <div style={{ margin: token.margin, width: "100%" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
              height: "60px",
              paddingRight: token.padding,
              alignItems: "center",
            }}
          >
            <Title level={3}>Administration</Title>
            <div
              style={{
                position: "sticky",
                marginTop: token.margin,
                height: "90px",
                width: "200px",
                border: "solid",
                borderColor: token.colorPrimary,
              }}
            >
              <VideoPlayer
                videoUrl={
                  QuickLinkMetaMap.get(QuickLinkComponent.Administration)
                    ?.url as string
                }
                thumbnailUrl={
                  QuickLinkMetaMap.get(QuickLinkComponent.Administration)
                    ?.thumbnail as string
                }
                playIconSize="small"
                pipMode={true}
              />
            </div>
          </div>
          <Tabs items={tabItems.filter((x) => x.visible)} />
        </div>
      }
    />
  );
};

export default Administration;
