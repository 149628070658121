import { useEffect, useState, FC } from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
  Button,
  Empty,
  Spin,
  Typography,
  theme,
  Space,
  Skeleton,
  Layout,
  Tooltip,
  Popover,
  Tag,
  Flex,
  Modal as AntdModal
} from "antd";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  SaveOutlined,
  StopOutlined,
} from "@ant-design/icons";
import { notification } from "utility/notification";
import pRetry, { FailedAttemptError } from "p-retry";
import { getStyles } from "utility/styles";

import { commonIcons, workflowIcons } from "assets/icons";
import { SvgIcon } from "components/SvgIcon";
import { useOrganizationStore, useSearchStore } from "store";
import PageWrapper from "components/PageWrapper";
import SaveSearchModal from "./SaveModal";
import ResultPanel from "./ResultPanel";
import { Search, SearchRunResultMeta, SearchRunStatus, SearchTabType } from "types";
import ControlButton from "components/ControlButton/controlButton";
import ActionPanel from "./ActionPanel";
import { Content } from "antd/es/layout/layout";
import { getAvatarColor, getTagColor } from "utility";
import { getSearchRunIdsListFromSearchRun } from "./Utils";
import { SummaryBanner } from "./summaryBanner";

const { Text } = Typography;

export const SearchRunComponent = () => {
  const { token } = theme.useToken();
  const classes = getStyles({
    container: {
      gap: token.marginXS,
      overflow: "hidden",
      marginLeft: token.margin,
      marginRight: token.margin,
      // marginTop: token.marginXS,
      marginBottom: token.margin,
      width: "100%"
    },
  })();
  const { id: searchRunId } = useParams();
  const [saveSearchModal, setSaveSearchModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const [showActionPanel, setShowActionPanel] = useState(false);
  const abortController = new AbortController();
  const context = useOrganizationStore((state) => state.context);
  const [tagHoverId, setTagHoverId] = useState("");
  const [filteredTags, setFilteredTags] = useState<string[]>([]);
  const [appSubscriptionHoverId, setAppSubscriptionHoverId] = useState("");
  const [filteredAppSubscriptions, setFilteredAppSubscriptions] = useState<string[]>([]);
  const [filterModal, setFilterModal] = useState<boolean>(false);
  const [activeResultMeta, setActiveResultMeta] = useState<SearchRunResultMeta | undefined>(undefined);

  const { mspEnabled, tags } = useOrganizationStore((state) => ({
    mspEnabled: state.mspEnabled,
    tags: state.tags,
  }));

  const {
    currentSearchRun,
    currentSearchRunTags,
    searchLoader,
    recommendedActions,
    savedSearchPage,
    activeSearchAppTabIndex,
    getSearchRun,
    getSearchRunRecommendedActions,
    getSearchRunActions,
    searchRunActionSelectedDatas,
    createSearch,
    cancelSearchRun,
    resetSearchState,
    getSearchItems,
  } = useSearchStore((state) => ({
    currentSearchRun: state.currentSearchRun,
    currentSearchRunTags: state.currentSearchRunTags,
    searchLoader: state.searchLoader,
    recommendedActions: state.recommendedActions,
    savedSearchPage: state.searchPage[SearchTabType.SavedSearches],
    activeSearchAppTabIndex: state.activeSearchAppTabIndex,
    getSearchRun: state.getSearchRun,
    getSearchRunRecommendedActions: state.getSearchRunRecommendedActions,
    getSearchRunActions: state.getSearchRunActions,
    searchRunActionSelectedDatas: state.searchRunActionSelectedDatas,
    createSearch: state.createSearch,
    cancelSearchRun: state.cancelSearchRun,
    resetSearchState: state.resetSearchState,
    getSearchItems: state.getSearchItems,
  }));

  const cancelSearch = async () => {
    if (!searchRunId) return;
    try {
      setLoader(true);
      await cancelSearchRun(searchRunId);
      notification.success({
        message: "Search cancellation triggered succesfully",
        duration: 6,
      });
    } catch (error) {
      console.log(error);
      notification.error({
        message: "Unable to cancel search",
        duration: 6,
      });
    } finally {
      setLoader(false);
    }
  };

  const saveSearch = async (
    name: string,
    description: string,
    serviceCode: string,
    selectedTags?: string[],
    tagsOnly?: boolean
  ) => {
    try {
      setLoader(true);
      const search = {
        name: name,
        description: description,
        createTags: selectedTags,
        serviceCode: serviceCode,
      } as Search;
      await createSearch(search, tagsOnly);
      await getSearchItems(
        SearchTabType.SavedSearches,
        savedSearchPage.number,
        savedSearchPage.size
      );
      setSaveSearchModal(false);
      setLoader(false);
      notification.success({
        message: "Search saved successfully",
        duration: 6,
      });
    } catch (error) {
      console.log(error);
      setLoader(false);
      notification.error({
        message: "Unable to save search",
        duration: 6,
      });
    }
  };

  const fetchRecommendedActions = async () => {
    try {
      await getSearchRunRecommendedActions(searchRunId);
    } catch (error) {
      console.log(error);
    }
  };

  const syncSearchRunInBackground = async () => {
    try {
      const sr = await getSearchRun(searchRunId);
      if (sr.status == SearchRunStatus.Running) {
        throw new Error(sr.status);
      }
    } catch (e) {
      if ((e as Error).message == SearchRunStatus.Running) {
        throw new Error(SearchRunStatus.Running);
      } else {
        //On failure of loading search run, it could be a unauthorized access, switch it to workspace home page
        navigate(`/workspace`);
      }
    }
  };

  useEffect(() => {
    resetSearchState(false);
    return () => {
      setActiveResultMeta(undefined)
      resetSearchState(false);
      abortController.abort("exiting search run page");
    };
  }, []);

  useEffect(() => {
    if (searchRunId && searchRunId != "") {
      const asyncUseEffect = async () => {
        try {
          fetchRecommendedActions();
          pRetry(() => syncSearchRunInBackground(), {
            retries: 60,
            minTimeout: 5000,
            maxTimeout: 5000,
            signal: abortController.signal,
          }).catch((e: FailedAttemptError) => {
            console.log(
              "pretry sync search run status completed, exiting sync of status"
            );
          });
        } catch (e) {
          console.log("failed to get search run actions", e);
        }
      };
      asyncUseEffect();
    }
  }, [searchRunId, context]);

  const showRunStatus = () => {
    switch (currentSearchRun?.status) {
      case SearchRunStatus.Running:
        return (
          <SvgIcon
            size={16}
            component={workflowIcons.logProgressIcon}
            hexColor={"orange"}
          />
        );
      case SearchRunStatus.Completed:
        return (
          <SvgIcon
            size={16}
            component={workflowIcons.logSuccessIcon}
            hexColor="green"
          />
        );
      case SearchRunStatus.Failed:
        return (
          <SvgIcon
            size={16}
            component={workflowIcons.logFailedIcon}
            hexColor="red"
          />
        );
      default:
        return <></>;
    }
  };

  const getApps = () => {
    return (
      <Flex gap="4px 4px" wrap="wrap" align={"left"}>
        {currentSearchRun.resultMetas.map((rm, index) => (
          <div
            key={rm.appSubscriptionID}
            onClick={(e) => {
              if (filteredAppSubscriptions.includes(rm.appSubscriptionID)) {
                setFilteredAppSubscriptions(filteredAppSubscriptions.filter((x) => x != rm.appSubscriptionID));
              } else {
                setFilteredAppSubscriptions([...filteredAppSubscriptions, rm.appSubscriptionID]);
              }
            }}
            style={{ cursor: "pointer" }}
            onMouseOver={() => setAppSubscriptionHoverId(rm.appSubscriptionID)}
            onMouseOut={() => setAppSubscriptionHoverId("")}
          >
            <Tag
              color={
                appSubscriptionHoverId == rm.appSubscriptionID
                  ? token.colorPrimaryBorderHover
                  : getAvatarColor(`${rm.appName}(${rm.appSubscriptionName})`)
              }
              icon={
                filteredAppSubscriptions.includes(rm.appSubscriptionID) ?
                  <CloseCircleOutlined />
                  :
                  <CheckCircleOutlined />
              }
            >
              {rm.appName}({rm.appSubscriptionName})
            </Tag>
          </div>
        ))}
      </Flex>
    );
  };

  const getTags = () => {
    return (
      <Flex gap="4px 4px" wrap="wrap" align={"left"}>
        {currentSearchRunTags.map((tagId) => (
          <div
            key={tagId}
            onClick={(e) => {
              if (filteredTags.includes(tagId)) {
                setFilteredTags(filteredTags.filter((x) => x != tagId));
              } else {
                setFilteredTags([...filteredTags, tagId]);
              }
            }}
            style={{ cursor: "pointer" }}
            onMouseOver={() => setTagHoverId(tagId)}
            onMouseOut={() => setTagHoverId("")}
          >
            <Popover
              content={`Searched using tag ${tags?.find((tag) => tag.id == tagId)?.value}`}
            >
              <Tag
                color={
                  tagHoverId == tagId
                    ? token.colorPrimaryBorderHover
                    : getTagColor(tagId)
                }
                icon={
                  filteredTags.includes(tagId) ? (
                    <CloseCircleOutlined />
                  ) : (
                    <CheckCircleOutlined />
                  )
                }
              >
                {tags?.find((tag) => tag.id == tagId)?.value}
              </Tag>
            </Popover>
          </div>
        ))}
      </Flex>
    );
  };

  const getFiltersModal = () => {
    return (
      <AntdModal
        title={"Filter Search Results"}
        // width={800}
        open={true}
        onCancel={() => setFilterModal(false)}
        footer={null}
      >
        <Space direction="vertical" size={1}>
          {mspEnabled && currentSearchRunTags.length > 1 && 
            <Space direction="vertical">
              <Text strong>Tag Based Filters</Text>
              <div
                style={{
                  marginLeft: "10px",
                  marginBottom: "10px"
                }}
              >
                {getTags()}
              </div>
            </Space>
          }
          {currentSearchRun.resultMetas.length > 1 && 
            <Space direction="vertical">
            <Text strong>App Based Filters</Text>
            <div
              style={{
                marginLeft: "10px",
                marginBottom: "10px"
              }}
            >
              {getApps()}
            </div>
          </Space>
          }
        </Space>
        <div style={{ position: "absolute", bottom: 1, right: 5 }}>
          <Space>
            <Button
              type="link"
              onClick={() => console.log("Reset Filters")}
              ghost
            >
              Reset Filters
            </Button>
          </Space>
        </div>
      </AntdModal>

    );
  }


  const getHeaderActionButtons = () => {
    return (
      <Space size={4}>
        <Space size={8}>
          <Button
            type="primary"
            icon={<SaveOutlined />}
            onClick={() => setSaveSearchModal(true)}
          >
            Save search
          </Button>
          {currentSearchRun.status == SearchRunStatus.Running && (
            <Button
              type="primary"
              icon={<StopOutlined />}
              onClick={cancelSearch}
            >
              Stop Search
            </Button>
          )}
        </Space>
        <Space size={0}>
          {recommendedActions.length ? (
            <Tooltip title="Take Actions">
              <Button
                icon={<SvgIcon component={workflowIcons.actionShortIcon} />}
                onClick={() => setShowActionPanel(true)}
                type="link" 
                ghost
              />
            </Tooltip>
          ) : null}
          {currentSearchRun.resultMetas?.length ? (
            <Tooltip title="Filters">
              <Button
                icon={<SvgIcon component={commonIcons.filterSortIcon} />}
                onClick={(e) => setFilterModal(true)}
                type="link" 
                ghost
              />
            </Tooltip>
          ) : null}
          {filterModal && getFiltersModal()}
        </Space>
      </Space>
    );
  };

  const getHeaderControlButtons = () => {
    return (
      <Space size={1}>
        <ControlButton
          displayName={"Back"}
          svgComponent={
            <SvgIcon
              onClick={() => {
                navigate(-1);
              }}
              component={commonIcons.backIcon}
            />
          }
          hoverable={true}
        />
      </Space>
    );
  };

  const getTitle = () => {
    let title = currentSearchRun.searchMetaData?.name;
    if (!title || title == "") {
      title = `search-runs/${searchRunId}`;
    }
    return (
      <Space size={8}>
        <Popover
          content={
            currentSearchRun.searchMetaData?.isAiBasedQuery
              ? currentSearchRun.searchMetaData.nativeQuery
              : title
          }
        >
          <Text style={{ fontSize: token.fontSizeHeading3 }}>
            Search Results
          </Text>
        </Popover>
        {showRunStatus()}
      </Space>
    );
  };

  useEffect(() => {
    if (
      currentSearchRun.status != SearchRunStatus.Running &&
      currentSearchRun.resultMetas &&
      currentSearchRun.resultMetas.length
    ) {
      const searchRunIdsList =
        getSearchRunIdsListFromSearchRun(currentSearchRun);
      if (searchRunIdsList != "") {
        getSearchRunActions(searchRunIdsList, 1, 50);
      }
    }
    if (!currentSearchRun?.resultMetas) setActiveResultMeta(undefined)
  }, [currentSearchRun]);

  useEffect(() => {
    if (!showActionPanel) {
      setShowActionPanel(
        Object.entries(searchRunActionSelectedDatas).length > 0
      );
    }
  }, [searchRunActionSelectedDatas]);

  useEffect(() => {
    if (activeSearchAppTabIndex != -1) {
      setActiveResultMeta(currentSearchRun.resultMetas[activeSearchAppTabIndex]);
    }else {
      setActiveResultMeta(undefined);
    }
  }, [activeSearchAppTabIndex]);

  return (
    <PageWrapper
      header={
        <Space style={{ display: "flex" }} size={0} direction="vertical">
          <div
            id="search-run-header"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
              padding: token.paddingXS,
              backgroundColor: token.colorBorderSecondary,
              alignItems: "center",
            }}
          >
            {getHeaderControlButtons()}
            {getTitle()}
            {getHeaderActionButtons()}
          </div>
          <div style={{ margin: token.margin, marginTop: token.marginXS, marginBottom: token.marginXS }}>
            <SummaryBanner 
              filteredAppSubscriptions={filteredAppSubscriptions}
              filteredTags={filteredTags}
            />
          </div>
        </Space>
      }
      content={
        <div id="search-run-container" className={classes.container}>
          <Layout
            style={{
              width: "100%",
              overflowY: "auto",
              overflowX: "hidden",
            }}
          >
            <Content
              style={{
                height: "100%",
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "row",
                }}
              >
                <div
                  id="search-run-table"
                  style={{
                    width:
                      currentSearchRun.resultMetas?.length &&
                        searchRunId &&
                        showActionPanel &&
                        recommendedActions.length
                        ? "75%"
                        : "100%",
                  }}
                >
                  <Spin spinning={searchLoader || loader}>
                    {activeResultMeta ? (
                      <ResultPanel
                        searchId={currentSearchRun.searchID}
                        appSubscriptionId={activeResultMeta.appSubscriptionID}
                        artifactName={activeResultMeta.artifactName}
                        resultMeta={activeResultMeta}
                        editMode={true}
                      />
                    ) : currentSearchRun.status ==
                      SearchRunStatus.Running ? (
                      <Skeleton
                        active={
                          currentSearchRun.status == SearchRunStatus.Running
                        }
                      />
                    ) : (
                      <Empty />
                    )}
                  </Spin>
                </div>
                {searchRunId &&
                  showActionPanel &&
                  recommendedActions.length ? (
                  <div
                    id="search-action-drawer"
                    style={{
                      marginLeft: token.marginXXS,
                      width: "25%",
                      minWidth: "25%",
                    }}
                  >
                    <ActionPanel
                      searchRunId={searchRunId}
                      artifactName={
                        currentSearchRun?.resultMetas?.[0]?.artifactName
                      }
                      open={showActionPanel}
                      onClose={() => setShowActionPanel(false)}
                    />
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </Content>
          </Layout>
          {saveSearchModal && (
            <SaveSearchModal
              onClose={() => setSaveSearchModal(false)}
              loader={loader}
              open={saveSearchModal}
              onSubmit={(
                name: string,
                description: string,
                serviceCode: string,
                selectedTags?: string[],
                tagsOnly?: boolean
              ) => saveSearch(name, description, serviceCode, selectedTags, tagsOnly)}
            />
          )}
        </div>
      }
    ></PageWrapper>
  );
};
