import { FC, useState, useEffect } from "react";
import {
  Dropdown,
  MenuProps,
  Card,
  Image,
  theme,
  Typography,
  Button,
  Space,
  Popover,
} from "antd";

import {
  DeleteOutlined,
  DeploymentUnitOutlined,
  DisconnectOutlined,
} from "@ant-design/icons";
import { SvgIcon } from "components/SvgIcon";

import {
  EdgeCardProps,
  EdgeDeployStatus,
  EdgeInfraType,
  EdgeManageType,
  EdgeStatus,
  EdgeStatusMap,
} from "types";
import { isEdgeTypeCloud } from "../../../utility/edge";

import { appIcons, commonIcons, edgeIcons } from "assets/icons";

import { getEdgeLogo } from "utility/edge/utils";
import {
  useMetricStore,
  useOrganizationStore,
  useSettingsStore,
  useAppConfigStore,
} from "store";

const { Text, Paragraph } = Typography;

//TBD - custome edge can have only one subscription for now.
//modify enable/disable if it supports multiple subscriptions.
const EdgeCard: FC<EdgeCardProps> = ({
  edge,
  deleteEdge,
  enableEdge,
  disableEdge,
}) => {
  const appState = useAppConfigStore((state) => state);
  const { token } = theme.useToken();
  const { mode } = useSettingsStore((state) => ({
    mode: state.lightMode,
  }));
  const [hover, setHover] = useState(false);
  const [isProduction, setIsProduction] = useState(false);

  useEffect(() => {
    setIsProduction(appState.isProduction);
  }, [appState]);

  const { mspEnabled, tags } = useOrganizationStore((state) => ({
    mspEnabled: state.mspEnabled,
    tags: state.tags,
  }));

  const handleMenuClick: MenuProps["onClick"] = (e) => {
    e.key == "enable" && enableEdge(edge?.id);
    e.key == "disable" && disableEdge(edge?.edgeSubscriptions?.[0].id);
    e.key == "delete" && deleteEdge(edge?.id);
  };

  const getMenuOptions = () => {
    const menuItems: MenuProps["items"] = [];

    if (edge?.edgeSubscriptions?.length) {
      menuItems.push({
        key: "disable",
        label: "Disable",
        icon: <DisconnectOutlined />,
      });
    } else {
      menuItems.push({
        key: "enable",
        label: "Enable",
        icon: <DeploymentUnitOutlined />,
      });
    }
    menuItems.push({
      key: "delete",
      label: "Delete",
      disabled: edge?.edgeSubscriptions?.length != 0,
      icon: <DeleteOutlined />,
    });

    if (menuItems.length) {
      return {
        items: menuItems,
        onClick: handleMenuClick,
      };
    } else {
      return {
        items: [],
      };
    }
  };

  const getEdgeStatusIcon = (state: EdgeStatus) => {
    switch (state) {
      case EdgeStatus.Online:
        return <SvgIcon component={edgeIcons.edgeAvailableStatusIcon} />;
      case EdgeStatus.Offline:
        return <SvgIcon component={edgeIcons.edgeUnavailableStatusIcon} />;
      case EdgeStatus.Installing:
      case EdgeStatus.Uninstalling:
      case EdgeStatus.Updating:
        return <SvgIcon component={edgeIcons.edgeOperationIcon} />;
      default:
        return <SvgIcon component={edgeIcons.edgeUnknownStatusIcon} />;
    }
  };

  const getEdgeSubscriptionStatusLogo = () => {
    if (edge?.edgeSubscriptions?.length) {
      return edgeIcons.edgeEnabledIcon;
    } else {
      return edgeIcons.edgeDisabledIcon;
    }
  };

  const cardDetails = [
    {
      content: (
        <Popover
          title={edge.region}
          content={
            <Text>
              Status:{" "}
              {isProduction && edge.infraType != EdgeInfraType.Amazon
                ? EdgeStatus.Offline
                : EdgeStatusMap.get(edge.status)}
            </Text>
          }
        >
          <div>{getEdgeStatusIcon(edge.status)}</div>
        </Popover>
      ),
    },
    {
      content:
        edge.deployStatus == EdgeDeployStatus.Completed ? (
          <Popover
            title="Metrics"
            content={
              <Space direction="vertical">
                <Text>IO Saved: 0 TB</Text>
                <Text>Time Saved: 0 Hours</Text>
              </Space>
            }
          >
            <div>
              <SvgIcon size={25} component={commonIcons.donutChartIcon} />
            </div>
          </Popover>
        ) : (
          <div>
            <SvgIcon size={25} component={commonIcons.donutChartIcon} />
          </div>
        ),
    },
    {
      content: (
        <SvgIcon
          size={25}
          component={
            isEdgeTypeCloud(edge?.infraType)
              ? edgeIcons.cloudEdgeIcon
              : edgeIcons.onPremiseEdgeIcon
          }
        />
      ),
    },
  ];

  return (
    <Card
      cover={
        <div
          style={{
            background: mode
              ? token.colorFillSecondary
              : token.colorPrimaryBgHover,
            height: "50px",
          }}
        >
          {mspEnabled && edge?.edgeSubscriptions?.length > 0 ? (
            <Popover
              title="Tags"
              content={
                <Space direction="vertical">
                  {edge?.edgeSubscriptions
                    ?.map((es) => es.tenantID)
                    .filter((t, i, self) => self.indexOf(t) == i)
                    .map((t) => (
                      <Text key={t}>
                        {tags?.find((tag) => tag.id == `${t}_tag`)?.value}
                      </Text>
                    ))}
                </Space>
              }
            >
              <div style={{ position: "absolute", top: 15, left: 25 }}>
                <SvgIcon
                  size={20}
                  component={getEdgeSubscriptionStatusLogo()}
                />
              </div>
            </Popover>
          ) : (
            <div style={{ position: "absolute", top: 15, left: 25 }}>
              <SvgIcon size={20} component={getEdgeSubscriptionStatusLogo()} />
            </div>
          )}
          <div style={{ position: "absolute", top: 5, left: 100 }}>
            <Image
              preview={false}
              draggable={false}
              width={"100px"}
              height={"40px"}
              src={getEdgeLogo(edge?.infraType, mode)}
              alt="company-logo"
            />
          </div>
          {edge.manageType == EdgeManageType.Custom && (
            <div style={{ position: "absolute", top: 10, right: 15 }}>
              <Dropdown menu={getMenuOptions()} placement="bottomRight">
                <Button
                  type="text"
                  icon={
                    <SvgIcon
                      size={20}
                      component={appIcons.appMoreOutlinedIcon}
                    />
                  }
                  ghost
                  onClick={(e: any) => e.stopPropagation()}
                />
              </Dropdown>
            </div>
          )}
        </div>
      }
      bodyStyle={{ display: "flex", height: "calc(100% - 38px)" }}
      style={{
        backgroundColor: hover ? token.colorBgElevated : token.colorBgLayout,
        boxShadow: token.boxShadow,
        height: "300px",
        width: "300px",
        cursor: "default",
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <div
        style={{
          width: "100%",
          height: "190px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          overflow: "auto",
          scrollbarWidth: "thin",
          padding: token.padding,
        }}
      >
        <Text style={{ fontSize: token.fontSizeHeading4, textAlign: "center" }}>
          {edge.displayName}
        </Text>
        <Paragraph>{edge.description}</Paragraph>
      </div>
      {cardDetails.map((item, index) => (
        <Card.Grid
          key={index}
          hoverable={false}
          style={{
            height: "50px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {item.content}
        </Card.Grid>
      ))}
    </Card>
  );
};

export default EdgeCard;
