import { AxiosResponse } from "axios";
import { CreationalSubscriber, MspState, Provider, Subscriber, SubscriberUpdateRequest } from "types";
import { axiosInterceptor } from "./axios";
import { useAppConfigStore } from "store";

/** Provider apis */
const getProvidersApiPath = () => {
  const appState = useAppConfigStore.getState();
  return appState.providersApiPath;
}


const getSubscribersApiPath = () => {
  const appState = useAppConfigStore.getState();
  return appState.subscribersApiPath;
}

export const getSubscribersApi = async () : Promise<AxiosResponse<{ tenants: Subscriber[]}>> => {
  return axiosInterceptor("get", `${getProvidersApiPath()}/subscriptions`);
}

export const createSusbscriberApi = async (organization : CreationalSubscriber) : Promise<AxiosResponse<Subscriber>> => { 
  return axiosInterceptor("post", `${getProvidersApiPath()}/subscriptions`, organization);
}

export const updateSubscriberApi = async (request : SubscriberUpdateRequest ) : Promise<AxiosResponse<Subscriber>> => {
  return axiosInterceptor("patch", `${getProvidersApiPath()}/subscriptions`, request)
};

export const getProviderStateApi = async () : Promise<AxiosResponse<MspState>> => { 
  return axiosInterceptor("get", `${getProvidersApiPath()}/self`);
}

export const updateProviderStateApi = async (active: boolean, external: boolean ) : Promise<AxiosResponse<MspState>> => { 
  return axiosInterceptor("post",`${getProvidersApiPath()}/self`,{active, external});
}

export const getProvidersApi = async () : Promise<AxiosResponse<{ providers: Provider[]}>> => { 
  return axiosInterceptor("get", `${getProvidersApiPath()}`);
}

/** Subscriber apis */
export const enrollWithProviderApi = async ( msspId: string) : Promise<AxiosResponse<any>> => {
  return axiosInterceptor("post", `${getSubscribersApiPath()}`,{ msspId });
}

export const getSubscriberStateApi = async () : Promise<AxiosResponse<MspState>> => { 
  return axiosInterceptor("get", `${getSubscribersApiPath()}`);
}

export const detachFromProviderApi = async () : Promise<AxiosResponse<any>> => { 
  return axiosInterceptor("delete", `${getSubscribersApiPath()}`);
}

export const acknowledgeProviderCancelApi = async () : Promise<AxiosResponse<any>> => { 
  return axiosInterceptor("delete",`${getSubscribersApiPath()}/ack`);
}

