
import axios, { AxiosResponse } from "axios";
import { RegistrationData } from "types/register";

export const registrationApi = async ( data :RegistrationData ): Promise<AxiosResponse<void>> => {
  const baseUrl = window.location.origin;
  const url = `${baseUrl}/provision/api/v1/setup`;
  const apiKey = data.authKey;
  const headers = {
    "Content-Type": "application/json",
    "x-api-key": apiKey
  };
  return axios.post(url, data, { headers });
};

