import axios, { AxiosResponse } from "axios";
import { ClientCredentialsCreateRequestDto, ClientCredentialsCreateResponseDto, ClientCredentialsUpdateRequestDto,ClientCredentialsListResponseDto, CreatePermissionSet, CreateRole, CreateUser, PermissionSetDto, RoleDto, UpdatePermissionSet, UpdateRole, UpdateUser, UserDto, ClientCredentialsUpdateResponseDto } from "types";
import { axiosInterceptor } from "./axios";
import { useAppConfigStore } from "store";


const getClientCredentialsApiPath = () => { 
  const appState = useAppConfigStore.getState();
  return appState.clientCredentialsApiPath;
}

const getPsetsApiPath = () => {
  const appState = useAppConfigStore.getState();
  return appState.permissionSetsApiPath;
}

const getRolesApiPath = () => {
  const appState = useAppConfigStore.getState();
  return appState.rolesApiPath;
}

const getUsersApiPath = () => {
  const appState = useAppConfigStore.getState();
  return appState.usersApiPath;
}



export const getEligibilityApi = async (): Promise<boolean> => true;
export const listUsersApi = async () : Promise<AxiosResponse<UserDto[]>> => axiosInterceptor("get", `${getUsersApiPath()}`);
export const getUserApi = async (userId: string) : Promise<AxiosResponse<UserDto>> => axiosInterceptor("get", `${getUsersApiPath()}/${userId}`);
export const createUserApi = async ( cu : CreateUser): Promise<AxiosResponse<UserDto>>  => axiosInterceptor("post", `${getUsersApiPath()}`, { ...cu });
export const updateUserApi = async ( u: UpdateUser): Promise<AxiosResponse<UserDto>>  => axiosInterceptor("patch", `${getUsersApiPath()}/${u.id}`, { ...u });
export const deleteUserApi = async (id: string): Promise<AxiosResponse<UserDto>>  => axiosInterceptor("delete", `${getUsersApiPath()}/${id}`);

export const listRolesApi = async () : Promise<AxiosResponse<RoleDto[]>> => axiosInterceptor("get", `${getRolesApiPath()}`);
export const createRoleApi = async (role: CreateRole): Promise<AxiosResponse<RoleDto>>  => axiosInterceptor("post", `${getRolesApiPath()}`, { ...role });
export const getRoleApi = async (id: string): Promise<AxiosResponse<RoleDto>>  => axiosInterceptor("get", `${getRolesApiPath()}/${id}`);
export const updateRoleApi = async (role: UpdateRole): Promise<AxiosResponse<RoleDto>>  => axiosInterceptor("patch", `${getRolesApiPath()}/${role.id}`, { ...role });
export const deleteRoleApi = async (id: string): Promise<AxiosResponse<RoleDto>>  => axiosInterceptor("delete", `${getRolesApiPath()}/${id}`);

export const listPermissionSetsApi = async () : Promise<AxiosResponse<PermissionSetDto[]>> => axiosInterceptor("get", `${getPsetsApiPath()}`);
export const createPermissionSetApi = async (ps: CreatePermissionSet): Promise<AxiosResponse<PermissionSetDto>>  => axiosInterceptor("post", `${getPsetsApiPath()}`, { ...ps });
export const getPermissionSetApi = async (id: string): Promise<AxiosResponse<PermissionSetDto>>  => axiosInterceptor("get", `${getPsetsApiPath()}/${id}`);
export const updatePermissionSetApi = async (ps: UpdatePermissionSet): Promise<AxiosResponse<PermissionSetDto>>  => axiosInterceptor("patch", `${getPsetsApiPath()}/${ps.id}`, { ...ps });
export const deletePermissionSetApi = async (id: string): Promise<AxiosResponse<PermissionSetDto>>  => axiosInterceptor("delete", `${getPsetsApiPath()}/${id}`);

export const listClientCredentialsApi = async (): Promise<AxiosResponse<ClientCredentialsListResponseDto>> => axiosInterceptor("get", `${getClientCredentialsApiPath()}`);
export const createClientCredentialsApi = async (cc: ClientCredentialsCreateRequestDto): Promise<AxiosResponse<ClientCredentialsCreateResponseDto>> => axiosInterceptor("post", `${getClientCredentialsApiPath()}`, { ...cc });
export const updateClientCredentialsApi = async (clientId: string , cc: ClientCredentialsUpdateRequestDto): Promise<AxiosResponse<ClientCredentialsUpdateResponseDto>> => axiosInterceptor("patch", `${getClientCredentialsApiPath()}/${clientId}`, { ...cc });
export const deleteClientCredentialsApi = async (clientId: string): Promise<AxiosResponse<void>> => axiosInterceptor("delete", `${getClientCredentialsApiPath()}/${clientId}`);

export const getUserApiWithToken = async (userId: string, token: string) : Promise<AxiosResponse<UserDto>> => {
  const url = `${getUsersApiPath()}/${userId}`;
  return axios.get(url, { headers: { Authorization: `Bearer ${token}` } });
}