import { Avatar, Popover, theme } from "antd";
import { AvatarSize } from "antd/es/avatar/AvatarContext";
import { FC } from "react";
import { useMediaQuery } from "react-responsive";
import { useSettingsStore } from "store";
import { BigScreenQuery, Item } from "types";

export interface ItemsGroupProps {
  items: Item[];
  size?: AvatarSize;
}

export const ItemsGroup: FC<ItemsGroupProps> = ({ items, size }) => {
  const { token } = theme.useToken();
  const isBigScreen = useMediaQuery(BigScreenQuery);
  const avatarBgColors = ["#f56a00", "#87d068", "#1677ff"];
  const { mode } = useSettingsStore((state) => ({
      mode: state.lightMode,
    }));

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: token.marginXS,
      }}
    >
      <Avatar.Group 
        max={{ 
          count: isBigScreen ? 5 : 3,
          style: {
            color: mode ? "#282876" : "#FFFFFF"
          } 
        }}
      >
        {items?.map((item: Item, index: number) => (
          <Popover content={item.description} key={item.id} color={token.colorPrimary}>
            <Avatar
              style={{
                color: mode ? "#282876" : "#FFFFFF"
                //backgroundColor: avatarBgColors[index % 3],
              }}
              size={size ? size : "small"}
            >
              {item.name?.substring(0, 2) || "NA"}
            </Avatar>
          </Popover>
        ))}
      </Avatar.Group>
    </div>
  );
};
