import { FC, useEffect, useState } from "react";
import {
  Collapse,
  Skeleton,
  Table,
  theme,
  Typography,
  Space,
  Pagination,
  Button,
  Spin,
  Empty
} from "antd";

import pRetry, { FailedAttemptError } from 'p-retry';
import {
  SearchRunResultMeta,
  SearchRunActionStatus,
  SearchRunAction,
  SearchRunActionData,
} from "types";
import {
  useSearchStore,
  useEdgeGlobalStore,
  useSearchArtifactStore,
  useSettingsStore,
} from "store";

import { getSearchResultsApi } from "api";
import { PlusCircleOutlined, MinusCircleOutlined } from "@ant-design/icons";
import RowActionResultDetail from "./rowActionResultDetail";
import RowActionResult from "./rowActionResult";
import { normalizeLanguageFromFileName } from "utility/search/normalize_language";
import { ColumnRender } from "./columnRender";


const { Text } = Typography;

const PAGE_SIZE = 15;

export interface ResultPanelProps {
  searchId: string;
  appSubscriptionId: string;
  artifactName: string;
  resultMeta: SearchRunResultMeta;
  editMode: boolean;
}

export const ResultPanel: FC<ResultPanelProps> = ({
  searchId,
  appSubscriptionId,
  artifactName,
  resultMeta,
  editMode,
}) => {
  const { token } = theme.useToken();
  const { lightMode } = useSettingsStore((state) => ({
    lightMode: state.lightMode,
  }));
  const [tableResults, setTableResults] = useState<any[]>([]);
  const [tableColumns, setTableColumns] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loader, setLoader] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);
  const [selectAllPageSelected, SetSelectAllPageSelected] = useState(false);
  const [allResultsSelected, setAllResultsSelected] = useState(false);
  const [needRowUpdates, setNeedRowUpdates] = useState(false);
  const [monitoringActions, setMonitoringActions] = useState<string[]>([]);
  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);
  const abortController = new AbortController();
  const {
    updateSelectedSearchResults,
    clearSelectedSearchResults,
    getSearchRunAction,
    getSearchRunActions,
    searchRunActions,
    searchRunActionSelectedDatas,
    showDetailedResults,
  } = useSearchStore((state) => ({
    updateSelectedSearchResults: state.updateSelectedSearchResults,
    clearSelectedSearchResults: state.clearSelectedSearchResults,
    getSearchRunAction: state.getSearchRunAction,
    getSearchRunActions: state.getSearchRunActions,
    searchRunActions: state.searchRunActions,
    searchRunActionSelectedDatas: state.searchRunActionSelectedDatas,
    showDetailedResults: state.showDetailedResults,
  }));

  const { artifacts, getArtifacts } = useSearchArtifactStore((state) => ({
    artifacts: state.artifacts,
    getArtifacts: state.getArtifacts,
  }));

  const getEdgeEndpoint = useEdgeGlobalStore((state) => state.getEdgeEndpoint);

  const selectAllRows = () => {
    const selectedList: number[] = [];
    tableResults.map((i: any, index: number) => {
      selectedList.push(index);
    });
    setSelectedRowKeys(selectedList);
  };


  const fetchData = async (detailedView = false) => {
    try {
      setLoader(true);
      const edgeEndpoint = await getEdgeEndpoint(resultMeta.edgeID);
      const rows = await getSearchResultsApi(
        edgeEndpoint,
        resultMeta.tenantID,
        searchId,
        resultMeta.searchRunID,
        appSubscriptionId,
        artifactName,
        currentPage,
        PAGE_SIZE,
        detailedView
      );
      if (!rows.length) return;

      let artifact = artifacts.find((x) => x.name == artifactName);
      if (artifacts.length == 0) {
        const artifacts = await getArtifacts();
        artifact = artifacts.find((x) => x.name == artifactName);
      }

      const processedResponse = rows.map((i: any, index: number) => {
        if (artifact?.name == "Code") {
          let language = i["language"];
          const filename = i["filename"];
          if (!language && filename) {
            language = normalizeLanguageFromFileName(filename);
            if (language) {
              i["language"] = language;
            }
          }
        }
        return {
          ...i,
          key: (currentPage - 1) * PAGE_SIZE + index,
          actions: [],
        };
      });

      const d = rows[0];
      const columns = [];

      for (const key in d) {
        let displayName = key;
        if (artifact) {
          const field = artifact.fields[key];
          if (field) {
            displayName = field.displayName;
            columns.push({
              title: displayName,
              order: field.order,
              render: (record: any) =>
                <ColumnRender
                  value={record[key]}
                  textType={field.textType}
                  language={record["language"]}
                  highlight={record["searchterm"]}
                ></ColumnRender>
            });
          }
        }
      }

      const sortedColumns: any[] = columns.sort(
        (column1, column2) => column1.order - column2.order
      );
      //action
      sortedColumns.push({
        title: "Actions",
        //fixed: 'right',
        render: (record: any) =>
          record.actions.length ? (
            <RowActionResult
              searchRunId={resultMeta.searchRunID}
              searchRunAction={record.actions[0]}
              rowIndex={record["key"]}
              showApp={showDetailedResults ? false : true}
            />
          ) : (
            <></>
          ),
      });
      setTableColumns(sortedColumns);
      setTableResults(processedResponse);
      allResultsSelected && selectAllRows();
      setNeedRowUpdates(true);
    } catch (error) {
      console.log(error);
    } finally {
      setLoader(false);
    }
  };

  const onRowsSelectChange = (newSelectedRowKeys: React.Key[]) => {
    if (newSelectedRowKeys.length == 0) {
      setSelectedRowKeys([]);
      clearSelectedSearchResults(appSubscriptionId);
    } else {
      const newList = newSelectedRowKeys.map((i) => Number(i));
      const data = {
        searchRunID: resultMeta.searchRunID,
        tenantId: resultMeta.tenantID,
        isSelectAll: false,
        rowIndexes: newList,
      } as SearchRunActionData;
      setSelectedRowKeys(newList);
      updateSelectedSearchResults(appSubscriptionId, data);
    }
  };

  const onRowsSelectAll = (selected: boolean) => {
    if (!selected) {
      clearSelectedSearchResults(appSubscriptionId);
      SetSelectAllPageSelected(false);
    } else {
      SetSelectAllPageSelected(true);
      const data = {
        searchRunID: resultMeta.searchRunID,
        tenantId: resultMeta.tenantID,
        isSelectAll: true,
        rowIndexes: [],
      } as SearchRunActionData;
      updateSelectedSearchResults(appSubscriptionId, data);
      selectAllRows();
    }
  };

  const onSelectAllRows = () => {
    const data = {
      searchRunID: resultMeta.searchRunID,
      tenantId: resultMeta.tenantID,
      isSelectAll: true,
      rowIndexes: [],
    } as SearchRunActionData;
    updateSelectedSearchResults(appSubscriptionId, data);
    setAllResultsSelected(true);
    selectAllRows();
  };

  const onClearAllRows = () => {
    clearSelectedSearchResults(appSubscriptionId);
    setSelectedRowKeys([]);
    setAllResultsSelected(false);
    SetSelectAllPageSelected(false);
  };

  const rowSelection = {
    preserveSelectedRowKeys: true,
    selectedRowKeys,
    onChange: onRowsSelectChange,
    onSelectAll: onRowsSelectAll,
  };

  const onPageChange = (page: number, pageSize: number) => {
    if (!allResultsSelected) {
      clearSelectedSearchResults(appSubscriptionId);
    }
    setCurrentPage(page);
  };

  useEffect(() => {
    if(resultMeta.count > 0)
      fetchData(showDetailedResults);
  }, [resultMeta, showDetailedResults, currentPage, lightMode]);

  useEffect(() => {
    Object.entries(searchRunActionSelectedDatas).length == 0 &&
      setSelectedRowKeys([]);
  }, [searchRunActionSelectedDatas]);

  useEffect(() => {
    return () => { abortController.abort("exiting search run result panel page") };
  }, []);

  const syncSearchRunActionInBackground = async (searchRunActionId: string) => {
    const sr = await getSearchRunAction(searchRunActionId, false);
    if (sr.status == SearchRunActionStatus.Running) {
      throw new Error(sr.status)
    }
  };

  useEffect(() => {
    const processedResponse = tableResults.map((element, index) => {
      const actions = [];
      for (const sra of searchRunActions) {
        if (!sra.actionDatas) continue;
        const actionData = sra.actionDatas[appSubscriptionId];
        if (!actionData) continue;
        if (actionData.searchRunID != resultMeta.searchRunID) continue;
        const keyIndex = element["key"];
        if (actionData.isSelectAll) {
          actions.push(sra);
        } else {
          for (const rowIndex of actionData.rowIndexes) {
            if (rowIndex == keyIndex) {
              actions.push(sra);
              break;
            }
          }
        }
      }
      return { ...element, actions: actions };
    });
    setTableResults(processedResponse);
    setNeedRowUpdates(false);
  }, [searchRunActions, needRowUpdates]);

  useEffect(() => {
    for (const sra of searchRunActions) {
      if (
        sra.status == SearchRunActionStatus.Running &&
        !monitoringActions.find((x: string) => x == sra.id)
      ) {
        setMonitoringActions([...monitoringActions, sra.id]);
        pRetry(() => syncSearchRunActionInBackground(sra.id), {
          retries: 60,
          minTimeout: 5000,
          maxTimeout: 5000,
          signal: abortController.signal
        }).then(() => {
          setNeedRowUpdates(true);
        }).catch((e: FailedAttemptError) => {
          console.log("pretry sync search run status completed, exiting sync of status");
        })

      }
    }
  }, [searchRunActions]);

  const getRowActions = (record: any) => {
    const rowActions: SearchRunAction[] = [];
    record.actions &&
      record.actions.map((action: SearchRunAction) => {
        rowActions.push(action);
      });
    return rowActions;
  };

  return (
    <Spin spinning={loader}>
      {!loader && resultMeta.count ? (
        <div style={{ width: "100%" }}>
          <Space
            size={token.size}
            direction="vertical"
            style={{ display: "flex" }}
          >
            {selectAllPageSelected && (
              <div
                style={{
                  backgroundColor: token.colorBgTextActive,
                  textAlign: "center",
                }}
              >
                {allResultsSelected && (
                  <Text>{`All ${resultMeta.count} for this app are selected.`}</Text>
                )}
                {!allResultsSelected &&
                  (
                    <Text>{`All ${PAGE_SIZE > selectedRowKeys.length ? selectedRowKeys.length : PAGE_SIZE} results on this page are selected.`}</Text>
                  )}
                {allResultsSelected && (
                  <Button
                    type="link"
                    onClick={onClearAllRows}
                  >{`Clear Selection`}</Button>
                )}
                {!allResultsSelected && (PAGE_SIZE < selectedRowKeys.length) && (
                  <Button
                    type="link"
                    onClick={onSelectAllRows}
                  >{`Select all ${resultMeta.count} results for this app`}</Button>
                )}
              </div>
            )}
            <Table
              rowSelection={editMode ? rowSelection : undefined}
              columns={tableColumns}
              dataSource={tableResults}
              pagination={false}
              style={{ overflowX: "auto", scrollbarWidth: "inherit" }}
              expandable={{
                expandedRowKeys: expandedRowKeys,
                onExpand: (expanded, record) => {
                  setExpandedRowKeys(expanded ? [record.key] : []);
                },
                expandedRowRender: (record) =>
                  record.actions &&
                  record.actions.length && (
                    <Space
                      size={token.size}
                      direction="vertical"
                      style={{ display: "flex" }}
                    >
                      {getRowActions(record).map(
                        (action: SearchRunAction, index: number) => (
                          <RowActionResultDetail
                            key={action.id}
                            searchRunId={resultMeta.searchRunID}
                            searchRunAction={action}
                            rowIndex={record["key"]}
                            expanded={!index}
                          />
                        )
                      )}
                    </Space>
                  ),
                rowExpandable: (record) => record.actions.length,
                expandIcon: ({ expanded, onExpand, record }) =>
                  record.actions.length ? (
                    expanded ? (
                      <MinusCircleOutlined
                        color={token.colorPrimary}
                        onClick={(e) => onExpand(record, e)}
                      />
                    ) : (
                      <PlusCircleOutlined
                        color={token.colorPrimary}
                        onClick={(e) => onExpand(record, e)}
                      />
                    )
                  ) : (
                    <></>
                  ),
                expandRowByClick: true,
              }}
            />
            <Pagination
              style={{ float: "right" }}
              current={currentPage}
              onChange={onPageChange}
              pageSize={PAGE_SIZE}
              total={resultMeta.count}
              showSizeChanger={false}
              hideOnSinglePage={true}
            />
          </Space>
        </div>
      ): 
        resultMeta.count  == 0 ? (
          <Empty/>
        ):(
          <Skeleton active />
        )
      }
    </Spin>
  );
};

export default ResultPanel;